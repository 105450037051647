nav.admin-tabs {
  ul.primary {
    border-color: $color-accent;
    margin-top: 20px;

    li {
      a {
        background: $color-accent;
        border-radius: 2px 2px 0px 0px;
        border-color: $color-accent;
        background: #ffde2f;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 800;
        padding: 3px 10px 0px;
        color: #261b29;
        &:hover {
          opacity: 0.6;
        }
      }

      &.active {
        a {
          border-bottom: 1px solid $color-accent;
          opacity: 0.6;
        }
      }
    }
  }
}