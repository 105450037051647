// Front page
//----------------------------------------------------

.front {

  .field-name-field-img-header {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    height: calc(20vh + 30vw);
    &:before {
      content: "";
      background: url('../img/logo-white.png');
      height: 72px;
      width: 220px;
      background-size: 100%;
      display: inline-block;
      align-self: center;
      display: none;
    }
    img {
      height: 0px !important;

      &.vert-logo {
        opacity: 1 !important;
        height: 100% !important;
        align-self: flex-end;
        width: auto;
      }
    }
  }

  .l-content .block-views {

    h2.block-title {
      @include font-size(12px);
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: $uniform;
      margin: 0 0 20px 0;
      padding: 10px 0;
      border-bottom-color: #46464b;
      border-bottom: 1px solid $color-divider;
    }

    .more-link, .views-more-link {
      display: block;
      @include icon-after(right);
      @include font-size(10px);
      letter-spacing: 1px;
      text-transform: uppercase;
      font-family: $geomanist;
      font-weight: 900;
      margin-top: -45px;
      margin-bottom: 45px;
      a {
        color: #fff;
        // &:hover {
        //   color: $color-link;
        // }
      }
      &:after {
        margin-left: 0px;
        font-weight: 900;
      }
    }

    .views-more-link {
      margin-top: 18px;
      margin-bottom: 0px;
      align-self: flex-end;
    }


  } // .l-content .block-views

} // .front {
