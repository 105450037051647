.node-type-program, .node-type-organization {
  .l-highlighted {
    align-items: center;
    margin-bottom: 5px;
    .b-addthis {
      align-items: center;
      align-content: center;
      display: flex;
      justify-content: center;
      a {
        margin-bottom: 0px;
        align-items: center;
        align-content: center;
        display: flex;
        justify-content: center;
      }
    }
  }
.node-program, .node-organization {
  display: flex;
  flex-wrap: wrap;
  .field-name-title {
    flex: 1 1 100%; 
  }
  .field-name-field-img-main {
    flex: 1 1 100%; 
  }
  .field-name-body {
    text-align: justify;
    @media screen and (min-width:768px){
     width: 60%;
     padding-right: 20px; 
    }
  }
  .view-info-per-node {
    font-family: $uniformCondensed;
    margin-left: 0px;
    border: 8px solid #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 10px $color-accent;
    max-width: 100% !important;
    width: 100%;
    align-self: flex-start;
    a {
      font-weight: 600;
    }

    .contacts {
      ul {
        list-style: none;
        li {
          margin-left: 5px;
          color: transparent;
          list-style: none;
          text-transform: uppercase;
          margin-bottom: 0px;
          font-family: $uniformCondensed;
          @include font-size(12px);
          font-weight: 900;
          ul.links {
            list-style: none;
            margin-bottom: -5px;
            margin-top: -20px;
            li {
              list-style: none;
              margin-bottom: 0px;
              a{
                font-family: $uniformCondensed;
                @include font-size(14px);
                font-weight: 700;
                margin-bottom: 0px;
                text-transform: capitalize;
                color: #261b29;
              }
            }
          }
          div.field-name-field-txt-rol {
            margin-left: 20px;
            margin-bottom: 6px;
            padding-left: 6px;
            @include font-size(12px);
            text-transform: capitalize;
            font-weight: 400;
            color: #000;
            border-left: 1px solid rgba(0,0,0,0.1);
            border-bottom: 1px solid rgba(0,0,0,0.1);
            display: inline-block;
          }
        }
      }
    }

    @media screen and (min-width:768px){
      // width: calc(40% - 20px);
      flex: 1 1 35%;
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 28px; 
     }
  }
}
}