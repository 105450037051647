////
/// Footer Layout
/// @group Layout
////

.l-footer {
  // @extend %container;
  padding-top: 20px;
  border-top: 1px solid #3b3540;
  display: flex;
  flex-direction: column;
}
