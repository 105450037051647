.b-organizaciones {
  .type {
    @include font-size(12px);
    text-transform: lowercase;

  }
  h3.title {
    margin-top: 0px;
    font-family: $uniformCondensed;
    text-transform: uppercase;
    @include icon-before(up);
    &:before {
      margin-right: 4px;
      color: rgba(255, 222, 47, 0.2);
      border: 1px solid rgba(255, 222, 47, 0.1);
      border-radius: 500px;
      padding: 6px;
      width: 35px;
    }
    a {
      color: $color-accent;
    }
  }
}