////
/// Branding Layout
/// @group Layout
////

// Branding (Logo, Site name, Site slogan)
//----------------------------------------------------
// .l-branding {margin-left: 10px;}
.l-branding h1.site-name {
  margin: 0;
}
.l-branding h1.site-name a {
  background-size: 100%;
  @include logo(logo, 92px, 30px, 0px 0px);
  // margin: 10px 0;
}

// Logos
//----------------------------------------------------
.b-logo-mcj,
.b-logo-presidencia,
.l-branding {
  .block-content {
    a {
      background-size: 100%;
      filter: grayscale(1);
      // &:hover {filter: grayscale(0);}
    }
    border-left: 1px solid $black;
    margin: 10px 0 10px 0px;
    padding: 0 12px 0 12px;
  }
}
.b-logo-mcj .block-content a {
  @include logo(logo-mcj-plain, 48px, 30px, 0px 0px);
}

.b-logo-presidencia {
  .block-content {
    border-left: 0px solid $black;
    a {
      // @include logo(logo-cr-plain, 45px, 30px, 0px 0px);
      @include logo(logo-bicentenario, 64px, 32px);
    }
  }
}

.l-branding {
  .block-content {
    @media screen and (max-width: 400px) {
      // border-left: 0px solid $black;
    }
  }
}
