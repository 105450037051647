.b-noticias {
  // .node-grid {
    .view-content { 
      .views-row {
        border-right: 1px dashed #4f425b;
        margin-bottom: 40px;

        &:nth-child(3n+3){
          border-right: 0px dashed #4f425b;
        }

        .front & {
          // margin-bottom: 0px;
        }

        span.label, span.date {
          display: none;
        }

        h2.title {
          margin-top: 0px;
          font-family: $uniform;
          font-weight: 700;
          @include font-size(16px);
          text-transform: uppercase;
        }

        .lead {
          @include font-size(15px);
          font-family: $uniform;
          font-weight: 300;
          line-height: 20px;
          color: #cdcdcf;
        }

        .views-more-link {
          a {
            color: #fff;
            &:hover {
              color: $color-link-hover;
            }
          }
        }
      }
    }
  // }
}