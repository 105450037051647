// Icons (Fontello)
//----------------------------------------------
$icons:  (
  home: "\f015",
  menu: "\f0c9",
  search: "\f002",
  rss: "\f09e",
  mail: "\f0e0",
  phone: "\f095",
  fax: "\f1ac",
  marker: "\f041",
  user: "\f007",
  tags: "\f02c",
  link: "\f08e",
  date: "\f073",
  time: "\f017",
  camera: "\f030",
  video: "\f16a",
  attach: "\f0c6",
  download: "\f019",
  share: "\f1e0",
  comment: "\f075",
  left: "\f104",
  right: "\f105",
  up: "\f106",
  down: "\f107",
  facebook: "\f09a",
  twitter: "\f099",
  google: "\f0d5",
  youtube: "\f167",
  vimeo: "\f27d",
  instagram: "\f16d"
);