////
/// Sitewide Layout
/// @group Layout
////

// Global
//----------------------------------------------------
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.l-page {
  background-color: #261b29;
}
.l-container {
  @extend %container;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: visible;
}
.l-main {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.l-content {
  order: 2;
}
.l-sidebar-first {
  order: 1;
}
.l-sidebar-second {
  order: 3;
}

// Medium
//----------------------------------------------------
@include breakpoint($m) {
  .l-main {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .l-content {
    flex: span(8);
    order: 1;
  }
  .l-sidebar-first,
  .l-sidebar-second {
    flex: 0 1 span(4);
    margin-left: auto;
  }
}

// Large
//----------------------------------------------------
@include breakpoint($l) {
  .l-main {
    flex-wrap: nowrap;
  }
  .l-content {
    flex: span(9);
    order: 2;
  }
  .l-sidebar-first,
  .l-sidebar-second {
    flex: 0 1 span(3);
  }
}
