////
/// Main Layout
/// @group Layout
////

.l-main {
  // @extend %container;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // padding: 20px;
  // background: #fff;
}

.l-highlighted {
  margin: 20px 0;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  @include font-size(12px);
  background-color: $color-accent;
  border: 8px solid $white;
  align-items: center;
  margin-bottom: 5px;
  flex-wrap: wrap;
  .b-addthis {
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: center;
    a {
      color: $black;
      margin-bottom: 0px;
      align-items: center;
      align-content: center;
      display: flex;
      justify-content: center;
    }
  }
  .contextual-links-region {
    @media screen and (max-width:768px ){
      width: 100%;
    }
  }

  .view-id-contact_per_node {
    .view-content {
      .views-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .phone {
          @include icon-before(phone);
        }
        .email {
          @include icon-before(mail);
        }
        .name-rol, .phone, .email {
          margin: 0 8px;

          &:before {
            color: #000;
            margin-right: 4px;
          }

          a {
            color: #000;
            &:hover {
              color: $color-link;
            }
          }

          @media screen and (max-width:768px ){
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
} // .l-highlighted

.node-type-audio,
.node-type-document,
.node-type-gallery,
.node-type-video,

.node-type-webform,

.node-type-article,
.node-type-event,

.node-type-organization,
.node-type-program,
.node-type-project,

.node-type-produccion {

  .view-mode-full {
    position: relative;
    padding: 20px;
    background: $white;
    color: $black;
    h2.page-title {
      text-transform: uppercase;
      font-family: $uniformCondensed;
      @include font-size(40px);
      font-weight: 900;
      margin-top: 0px;
      margin-bottom: 10px;
      color: #261b29;
      // &:after {
      //   content: '';
      //   background: url(../img/logo.svg);
      //   width: 90px;
      //   height: 30px;
      //   display: block;
      //   background-size: contain;
      //   opacity: 0.1;
      // }
    }
    .field-name-field-img-main img {
      width: 100%;
    }
  }
}

.node-type-organization {
  .view-mode-full {
    padding: 50px 20px 20px 20px;
  }
}

.view-info-per-node {
  overflow: hidden;
  padding: 20px;
  float: right;
  max-width: 50%;
  background-color: $color-accent;
  border: 8px solid $white;
  color: $black;

  h2.title {
    text-align: center;
    text-transform: uppercase;
    font-family: $uniformExtraCondensed;
    &:before {
      content: '';
      width: 100%;
      height: 90px;
      background: url('/sites/all/themes/raiz/img/logo.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      margin: -30px 0px 10px 0px;
      opacity: 0.1;
    }
  }

  @include breakpoint($m) {
    max-width: 50%;
    float: right;
    margin-left: 10px;
  }

}


// Noticia
.node-type-article {
  h2.page-title {
    background: #261b29;
    color: #fff !important;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px !important;
    padding-top: 20px;
    font-family: $uniformExtraCondensed;
    @include font-size(48px);
    line-height: 40px;
    text-align: center;
  }

  .field-name-field-txt-bajada {
    background: #261b29;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px !important;
    font-weight: 300;
    @include font-size(13px);
    text-align: center;
    text-transform: uppercase;
    padding-top: 10px;
    margin-bottom: -30px !important;
  }

  .field-name-post-date {
    background: #261b29;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px !important;
    font-weight: 700;
    @include font-size(13px);
    text-align: center;
    letter-spacing: 2px;
    padding-top: 14px;
    padding-bottom: 20px;

    &:before {
      content: '';
      height: 2px;
      width: 200px;
      display: block;
      position: relative;
      background: linear-gradient(to right, #f37d8d 0%, #f37d8d 50%, #fff 50%, #fff 100%);
      margin: 16px auto;
    }
  }

  .field-name-field-img-main {
    margin-top: 20px;
  }

  .field-name-field-img-footer {
    @include font-size(14px);
    font-style: italic;
  }

  .field-name-field-tax-tags {
    font-weight: 900;
    text-transform: uppercase;
    @include font-size(16px);
    font-family: $uniformCondensed;
    margin-top: 10px;
  }
}


// Evento
.node-type-event {

  .view-mode-full {
    display: flex;
    flex-wrap: wrap;

    .field-name-title {
      flex: 1 1 100%;
      h2.page-title {
        background: #261b29;
        color: #fff;
        margin-left: -20px;
        margin-right: -20px;
        margin-top: -20px !important;
        padding-top: 20px;
        font-family: $uniformExtraCondensed;
        @include font-size(48px);
        line-height: 40px;
        text-align: center;
        padding-bottom: 14px;
      }
    }

    .field-name-field-txt-bajada {
      background: #261b29;
      color: #fff;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -20px !important;
      margin-bottom: 20px;
      font-weight: 300;
      @include font-size(13px);
      text-align: center;
      text-transform: uppercase;
    }

    .field-name-field-img-main {
      flex: 1 1 50%;
      @media screen and (max-width: 768px){
        flex: 1 1 100%;
      }
    }

    .group-info {
      flex: 1 1 50%;
      padding: 1% 2%;
      @media screen and (max-width: 768px){
        flex: 1 1 100%;
      }

      .field {
        margin-bottom: 10px;
        font-weight: 100;
        @include font-size(18px);
        text-transform: uppercase;
        font-family: $uniformCondensed;
        .label-above {
          font-weight: 900;
          @include font-size(13px);
          text-transform: uppercase;
          font-family: $uniformCondensed;
        }
      }
    }
  }
}


// Proyecto
.node-type-project {
  .field-name-title {
    flex: 1 1 100%;
    h2.page-title {
      background: #261b29;
      color: #fff;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -20px !important;
      padding-top: 20px;
      font-family: $uniformExtraCondensed;
      @include font-size(48px);
      line-height: 40px;
      text-align: center;
      padding-bottom: 14px;
    }
  }
  .group-info {
    flex: 1 1 50%;
    @media screen and (max-width: 768px){
      flex: 1 1 100%;
    }

    .field {
      margin-bottom: 10px;
      font-weight: 100;
      @include font-size(18px);
      text-transform: uppercase;
      font-family: $uniformCondensed;
      .label-above {
        font-weight: 900;
        @include font-size(13px);
        text-transform: uppercase;
        font-family: $uniformCondensed;
      }
    }
  }
}

// Medios
.node-type-video, .node-type-audio, .node-type-gallery, .node-type-document   {
  .l-highlighted {
    display: none;
  }

  .field-name-title {
    flex: 1 1 100%;
    h2.page-title {
      background: #261b29;
      color: #fff;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -20px !important;
      margin-bottom: 20px;
      padding-top: 20px;
      font-family: $uniformExtraCondensed;
      @include font-size(48px);
      line-height: 40px;
      text-align: center;
      padding-bottom: 14px;
    }
  }

  .field-name-field-txt-bajada {
    background: #261b29;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px !important;
    margin-bottom: 20px;
    font-weight: 300;
    @include font-size(13px);
    text-align: center;
    text-transform: uppercase;
  }
}

// Nodos
.page-node, .page-taxonomy, .page-catalogos {
  h2.page-title, h1.page-title {
    font-family: $uniformExtraCondensed;
    @include font-size(48px);
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
  }
}


// Contacto 
.node-type-contact {
  .field-name-field-img-main {
    text-align: center;
    img {
      width: 160px;
      margin: 50px auto 0px;
      border-radius: 5000px;
    }
  }
}