.b-catalogos, .page-catalogos {
  h3.title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: $uniformExtraCondensed;
    @include font-size(14px);
    text-transform: uppercase;
    @include icon-before(tags);
    display: flex;
    align-items: center;
    // padding: 4px;
    background: $color-accent;
    border-radius: 2px;
    &:before {
      background:rgba(#261b29, 0.2);
      margin-right: 6px;
      color: $color-accent;
      padding: 15px 6px;
      width: 30px;
    }
    a {
      color: #261b29;
      &:hover {
        color: $color-link-hover;
      }
    }
  }
}

.page-catalogos {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 5%;
    .views-row {
      flex: 1 1 100%;
      padding: 0 2%;
      @include breakpoint($s) {
        flex: 1 1 100%;
      }
      @include breakpoint($m) {
        flex: 1 1 50%;
        max-width: 50%;
      }
      h3 {
        text-align: left;
        margin-bottom: 10px !important;
      }
    }
  }
}