////
/// Featured Layout
/// @group Layout
////

.l-featured {
  background-size: cover;
  width: 100% !important;

  .field-name-field-img-header, .field-name-field-img-main {
    background-size: cover;
    width: 100% !important;
  }

  // @include when-inside('.front') {
  //   @extend %container;
  //   // border-bottom: 1px solid $color-divider;
  //   margin: 20px 0 0px;
  //   padding: 20px 0;
  // }

  .block-ds-extras {

    .block-content {
      // position: relative;
      display: flex;
      // align-items: center;
      // align-content: center;
      justify-content: center;
    } // .block-content

    .field-name-field-img-header, .field-name-field-img-main  {
      img {
        width: 100%;
        max-width: 100%;
        height:auto;
        opacity: 0;
      }
    } // .field-name-field-img-header

    .field-name-title {
      position: absolute;
      align-self: center !important;
      transform-origin: center center;
      margin-top: 0px;

      h2 {
        @include font-size(40px);
        font-family: $uniformCondensed;
        font-weight: 900;
        text-transform: uppercase;
        border: 2px solid;
        padding: 10px;
        @include when-inside('.front') {
          // display: none;
          @extend .visually-hidden;
        }
      }
    } // .field-name-title

  } // .block-ds-extras {

} // .l-featured
