.b-contactos {
  .view-content{
    .views-row {
      flex: 1 1 48%;
      max-width: 48%;
      margin: 1%;
      padding: 2%;
      padding-left: 4%;
      background: #fff;
      color: #261b29;
      font-family: $uniformExtraCondensed;
      border-radius: 2px;
      &:before {
        content: '';
        height: 115px;
        display: inline-block;
        position: relative;
        float: left;
        background:linear-gradient(to bottom, #e5f902 0%, #e5f902 50%, #b610ea 50%, #b610ea 100%);
        width: 2px;
        margin-left: -5%;
        @media screen and (max-width:768px){
          margin-left: -2.5%;
        }
      }

      @media screen and (max-width:768px){
        flex: 1 1 100%;
        max-width: 100%;
      }
      a {
        color: #22c6dc;
      }

      .photo {
        float: left;
        margin-right: 10px;
        img {
          max-width: 100px;
          border-radius: 500px;
          margin-top: 10px;
          margin-bottom: -10px;
        }
      }

      .title {
        @include font-size(18px);
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .rol {
        text-transform: uppercase;
        @include font-size(27px);
        font-weight: 900;
        line-height: 27px;
        margin: 8px 0px;
      }

      .phone {
        a {
          @include font-size(15px);
          color: #261b29;
          @include icon-before(phone);
          &:before {
            margin-right: 4px;
          }
        }
      }

      .email {
        a {
          @include font-size(15px);
          @include icon-before(mail);
          &:before {
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.theme-contactos {
  .block-formblock {
    background: #fff;
    padding: 20px;
    color: #261b29;
    border: 4px solid #261b29;
    box-shadow: 0 0 0px 8px #fff;
    width: calc(100% - 32px);
    margin: auto;
    h2.block-title {
      color: #261b29;
      font-family: $uniformExtraCondensed;
      @include font-size(36px);
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    label {
      text-transform: uppercase;
      font-family: $uniformExtraCondensed;
    } 

    input, textarea {
      border-radius: 0px !important;
      border-top: 0px solid #fff !important;
      border-left: 0px solid #fff !important;
      border-right: 0px solid #fff !important;
      font-family: $uniform;
      margin-bottom: 20px;
      width: 100%;
    }

    input[type="checkbox"], label.option {
      display: inline-block;
      width: auto;
      margin-bottom: 10px;
    }

    label.option {
      top: -3px;
      position: relative;
  }
  }
}