
.b-proyectos {  
  .view-content {
    align-items: flex-start;
    .views-row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 60px;

      figure {
        flex: 1 1 100%;
      }
      
      img {
        margin-bottom: 0px;
      }

      h2.title {
        flex: 1 1 100%;
        @include font-size(20px);
        text-transform: uppercase;
        font-family: $uniformCondensed;
        margin-top: 6px;
        font-weight: 900;
        line-height: 24px;
        margin-top: 0px;
        padding: 8px 4px 4px;
        text-align: center;
        background: rgba(255,255,255,0.05);
      }

      span {
        @include font-size(12px);
        font-weight: 600;
        text-transform: uppercase;
        padding: 4px 5px 2px;
        line-height: 16px;
        flex: 1 1 100%;
        &.programa {
          background: rgba(255, 255, 255, 0.1);
          display: block;
          font-weight: 500;
          text-align: center;
          color: $color-accent;
        }
        &.date {
          background: rgba(255, 255, 255, 0.15);
          color: $color-accent;
          text-align: center;
          display: block;
        }
      } // .views-row
    } // .view-content
  }
} // .b-proyectos