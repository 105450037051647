.l-header-region {
  flex: 5;
  @media screen and (max-width: 800px){
    flex: 3;
  }
  .block-search {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    flex: 1;
    @media screen and (max-width: 680px){
      display: none;
    }

    .block-content {
      justify-content: center;
      align-items: center;
      align-content: center;
      display: flex;

      // input[type="search"] {
      //   background: transparent;
      //   border-radius: 0px;
      //   border-top: 0px !important;
      //   border-left: 0px !important;
      //   border-right: 0px !important;
      //   border-bottom: 1px solid black !important;
      //   min-width: 30vw;
      //   @media screen and (max-width: 768px){
      //     min-width: 25vw;
      //   }
      // }

      // input[type="submit"] {
      //   background-image: url(../img/search.png);
      //   background-position: center center;
      //   background-repeat: no-repeat;
      //   color: transparent;
      //   &:hover {
      //     opacity: 0.3;
      //   }
      // }
    }
  }
}