// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

// $slick-font-path: "./fonts/" !default;
// $slick-font-family: "slick" !default;
// $slick-loader-path: "./" !default;
// $slick-arrow-color: white !default;
// $slick-dot-color: black !default;
// $slick-dot-color-active: $slick-dot-color !default;
// $slick-prev-character: "\2190" !default;
// $slick-next-character: "\2192" !default;
// $slick-dot-character: "\2022" !default;
// $slick-dot-size: 6px !default;
// $slick-opacity-default: 0.75 !default;
// $slick-opacity-on-hover: 1 !default;
// $slick-opacity-not-active: 0.25 !default;

// @function slick-image-url($url) {
//   @if function-exists(image-url) {
//     @return image-url($url);
//   }
//   @else {
//     @return url($slick-loader-path + $url);
//   }
// }

// @function slick-font-url($url) {
//   @if function-exists(font-url) {
//     @return font-url($url);
//   }
//   @else {
//     @return url($slick-font-path + $url);
//   }
// }

// Slider
//----------------------------------------------------
.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}

// Icons@if $slick-font-family == "slick" {
//----------------------------------------------------
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }

// Arrows
//----------------------------------------------------
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      // opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    // opacity: $slick-opacity-not-active;
  }
  &:before {
    // font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    // color: $slick-arrow-color;
    // opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -25px;
  &:before {
    // content: $slick-prev-character;
  }
}

.slick-next {
  right: -25px;
  &:before {
    // content: $slick-next-character;
  }
}

// Dots
//----------------------------------------------------
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          // opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        // content: $slick-dot-character;
        width: 20px;
        height: 20px;
        // font-family: $slick-font-family;
        // font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        // color: $slick-dot-color;
        // opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      // color: $slick-dot-color-active;
      // opacity: $slick-opacity-default;
    }
  }
}


.slick--view--frontpage-slideshow {
  padding-left: 18px;
  border-left: 2px solid;
  border-image: linear-gradient(to bottom, #e5f902, #e5f902 50%, #097beb 50%, #097beb) 1 100%;

  .slide__content {
    flex: 1 0 span(6);
    display: flex;
    flex-direction: column;
    @include breakpoint($m) {
      flex-direction: row;
      .slide__media {order: 2;}
      .slide__caption {order: 1;}
    }
    .slide__media {
      flex: 1 0 span(6);
      margin-bottom: 10px;
    }

    .slide__caption {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex: 1 0 span(6);
      padding-right: 10px;
      h2.slide__title {
        flex: 1 100%;
        margin-top: 0px;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 35px;
        font-family: $uniformExtraCondensed;
        @include font-size(35px);
        a {
          &:hover {
            // color: $color-link;
          }
        }
      }  

      .slide__description {
        @include font-size(15px);
        line-height: 18px;
        flex: 1 100%;
        margin-bottom: 10px;
        color: #cdcdcd;
        .slide-type {
          @include font-size(11px);
          font-family: $uniformCondensed;
          font-weight: 800;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #22c6dc;
        }
        .slide-event-date {
          text-transform: uppercase;
          letter-spacing: 2px;
          @include font-size(13px);
        }
      }

      .slide__link {
        flex: 1 100%;
        align-self: flex-end;
        a {
          padding: 8px 16px 7px;
          background: #097beb;
          color: #fff;
          text-transform: uppercase;
          font-weight: 800;
          line-height: 12px;
          font-family: $uniform;
          line-height: 12px;
          letter-spacing: 2px;
          @include font-size(12.5px);
          @include icon-after(right);
          &:after {
            margin-left: 5px;
            font-weight: 900;
          }

          &:hover {
            background: $color-accent;
            color: #000;
          }
        }
      }
    }    
  }

  .slick-dots {
    margin: 0;
    bottom: -40px;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    // @include icon-after(right);
    // @include icon-before(left);
    &:after {
      margin-left: 5px;
      font-weight: 900;
      line-height: 24px;
    }
    &:before {
      margin-right: 5px;
      font-weight: 900;
      line-height: 24px;
    }
    li {
      cursor: pointer;
      transition: all 250ms ease-in-out;
      font-size: 0;
      line-height: 0;
      margin: 4px;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background: $white;
      &:after {
        content: '0';
        color: rgba(0,0,0,0.2);
        width: 18px;
        height: 18px;
        display: block;
        left: 0px;
        top: 0px;
        @include font-size(10px);
        font-weight: 700;
        line-height: 20px;
      }
      &:nth-child(1) {
        &:after {
          content: '1';
        }
      }
      &:nth-child(2) {
        &:after {
          content: '2';
        }
      }
      &:nth-child(3) {
        &:after {
          content: '3';
        }
      }
      &:nth-child(4) {
        &:after {
          content: '4';
        }
      }
      &:nth-child(5) {
        &:after {
          content: '5';
        }
      }
      &:nth-child(6) {
        &:after {
          content: '6';
        }
      }
      &:hover, &:focus {
        &:after {
          color: black;
        }
      }
      &.slick-active {
        &:after {
          color: black;
        }
      }
    }
  }
}

.b-frontpage-slideshow {
  max-width: 960px;
  margin: auto;
  padding: 40px 20px 20px;
}