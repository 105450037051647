.l-content .block-views {
  
      h2.block-title {
        @include font-size(12px);
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: $uniform;
        margin: 0 0 20px 0;
        padding: 10px 0;
        border-bottom-color: #46464b;
        border-bottom: 1px solid $color-divider;
      }
  
      .more-link, .views-more-link {
        display: block;
        @include icon-after(right);
        @include font-size(10px);
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: $geomanist;
        font-weight: 900;
        margin-top: -45px;
        margin-bottom: 45px;
        a {
          color: #fff;
          // &:hover {
          //   color: $color-link;
          // }
        }
        &:after {
          margin-left: 0px;
          font-weight: 900;
        }
      }
  
      .views-more-link {
        margin-top: 18px;
        margin-bottom: 0px;
        align-self: flex-end;
      }
  
  
    } // .l-content .block-views