/**
 * LISTS
 **********************************************/

// Menus
//----------------------
.menu, ul.menu li {
  @extend %list-reset;
  a {
    display: block;
  }
}
