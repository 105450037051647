// ADDTHIS
//----------------------------------------------
.b-addthis {
  display: flex;
  text-align: center;
  // margin: 10px 0;
  span {
    font-size: 0;
    visibility: hidden;
    line-height: 0;
  }
  #facebook,
  #twitter,
  #google,
  #email {
    @include font-size(20px);
    @include size(32px);
    line-height: 32px;
    color: $black;
  }
  #facebook {
    @include icon-before(facebook);
    // background: color($facebook);
    &:hover {
      color: $facebook;
      // background: color($facebook, light);
    }
  }
  #twitter {
    @include icon-before(twitter);
    // background: color($twitter);
    &:hover {
      color: $twitter;
      // background: color($twitter, light);
    }
  }
  // #google {
  //   @include icon-before(google);
  //   background: color($google);
  //   &:hover {
  //     background: color($google, light);
  //   }
  // }
  // #email {
  //   @include icon-before(mail);
  //   background: color($color-accent);
  //   &:hover {
  //     background: color($color-accent, light);
  //   }
  // }
}
