////
/// Variables
/// @group Abstracts
/// This file contains application-wide Sass variables.
////

@import "fonts";
@import "colors";
@import "icons";

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../' !default;

// Susy
//----------------------------------------------------
@include border-box-sizing;
$susy: (
  columns: 12,
  container: 1000px,
  global-box-sizing: border-box
);

// Breakpoints
//----------------------------------------------------
$xs: 320px;
$s:  480px;
$m:  768px;
$l:  960px;
$xl:  1280px;

// Colors
//----------------------------------------------------
$color-primary:        #f27e8b;
$color-accent:         #ffde2f;
$color-primary-text:   $white;
$color-secondary-text: $gray;
$color-divider:        #46464b;

$base-font-color:      $color-primary-text;
$color-link:           $color-primary;
$color-link-hover:     darken($color-link, 10%);

// Typography
//----------------------------------------------
$base-font-family:   $uniform;
$base-font-weight:   300;
$base-font-size:     15px;
$base-line-height:   18px;
$header-font-family: $uniform;
$header-font-weight: 700;
