////
/// Colors
/// @group Variables
////

// Social Colors
//----------------------------------
$facebook:  #3b5998;
$twitter:   #55acee;
$google:    #dd4b39;
$youtube:   #b00;
$vimeo:     #aad450;
$instagram: #517fa4;


$aqua:    #7fdbff;
$blue:    #0074d9;
$navy:    #001f3f;
$teal:    #39cccc;
$green:   #2ecc40;
$olive:   #3d9970;
$lime:    #01ff70;

$yellow:  #ffdc00;
$orange:  #ff851b;
$red:     #ff4136;
$fuchsia: #f012be;
$purple:  #b10dc9;
$maroon:  #85144b;

$white:   #fff;
$silver:  #d7d7d7;
$gray:    #aaa;
$black:   #111;
