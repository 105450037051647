////
/// Header Layout
/// @group Layout
////


// Header
//----------------------------------------------------
.l-header {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: visible;
  @include bleed-x(100%);
  background-color: $color-accent;
  border-top: 2px solid #f4ff73;
  flex: 100%;
  max-width: 960px;
}

// Header region
//----------------------------------------------------
.l-header-region {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 400px) {
    justify-content: center;
  }
}
