@charset "UTF-8";
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

@font-face {
  font-family: 'fontello';
  src: url(../fonts/fontello/font/fontello.eot);
  src: url(../fonts/fontello/font/fontello.eot#iefix) format("embedded-opentype"), url(../fonts/fontello/font/fontello.woff) format("woff"), url(../fonts/fontello/font/fontello.ttf) format("truetype"), url(../fonts/fontello/font/fontello.svg#fontello) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-thin.eot);
  src: url(../fonts/geomanist/geomanist-thin.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-thin.woff2) format("woff2"), url(../fonts/geomanist/geomanist-thin.woff) format("woff"), url(../fonts/geomanist/geomanist-thin.ttf) format("truetype"), url(../fonts/geomanist/geomanist-thin.svg#geomanist) format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-thin-italic.eot);
  src: url(../fonts/geomanist/geomanist-thin-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-thin-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-thin-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-thin-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-thin-italic.svg#geomanist) format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-extralight.eot);
  src: url(../fonts/geomanist/geomanist-extralight.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-extralight.woff2) format("woff2"), url(../fonts/geomanist/geomanist-extralight.woff) format("woff"), url(../fonts/geomanist/geomanist-extralight.ttf) format("truetype"), url(../fonts/geomanist/geomanist-extralight.svg#geomanist) format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-extralight-italic.eot);
  src: url(../fonts/geomanist/geomanist-extralight-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-extralight-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-extralight-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-extralight-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-extralight-italic.svg#geomanist) format("svg");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-light.eot);
  src: url(../fonts/geomanist/geomanist-light.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-light.woff2) format("woff2"), url(../fonts/geomanist/geomanist-light.woff) format("woff"), url(../fonts/geomanist/geomanist-light.ttf) format("truetype"), url(../fonts/geomanist/geomanist-light.svg#geomanist) format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-light-italic.eot);
  src: url(../fonts/geomanist/geomanist-light-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-light-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-light-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-light-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-light-italic.svg#geomanist) format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-regular.eot);
  src: url(../fonts/geomanist/geomanist-regular.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-regular.woff2) format("woff2"), url(../fonts/geomanist/geomanist-regular.woff) format("woff"), url(../fonts/geomanist/geomanist-regular.ttf) format("truetype"), url(../fonts/geomanist/geomanist-regular.svg#geomanist) format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-regular-italic.eot);
  src: url(../fonts/geomanist/geomanist-regular-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-regular-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-regular-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-regular-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-regular-italic.svg#geomanist) format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-book.eot);
  src: url(../fonts/geomanist/geomanist-book.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-book.woff2) format("woff2"), url(../fonts/geomanist/geomanist-book.woff) format("woff"), url(../fonts/geomanist/geomanist-book.ttf) format("truetype"), url(../fonts/geomanist/geomanist-book.svg#geomanist) format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-book-italic.eot);
  src: url(../fonts/geomanist/geomanist-book-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-book-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-book-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-book-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-book-italic.svg#geomanist) format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-medium.eot);
  src: url(../fonts/geomanist/geomanist-medium.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-medium.woff2) format("woff2"), url(../fonts/geomanist/geomanist-medium.woff) format("woff"), url(../fonts/geomanist/geomanist-medium.ttf) format("truetype"), url(../fonts/geomanist/geomanist-medium.svg#geomanist) format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-medium-italic.eot);
  src: url(../fonts/geomanist/geomanist-medium-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-medium-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-medium-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-medium-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-medium-italic.svg#geomanist) format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-bold.eot);
  src: url(../fonts/geomanist/geomanist-bold.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-bold.woff2) format("woff2"), url(../fonts/geomanist/geomanist-bold.woff) format("woff"), url(../fonts/geomanist/geomanist-bold.ttf) format("truetype"), url(../fonts/geomanist/geomanist-bold.svg#geomanist) format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-bold-italic.eot);
  src: url(../fonts/geomanist/geomanist-bold-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-bold-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-bold-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-bold-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-bold-italic.svg#geomanist) format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-black.eot);
  src: url(../fonts/geomanist/geomanist-black.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-black.woff2) format("woff2"), url(../fonts/geomanist/geomanist-black.woff) format("woff"), url(../fonts/geomanist/geomanist-black.ttf) format("truetype"), url(../fonts/geomanist/geomanist-black.svg#geomanist) format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-black-italic.eot);
  src: url(../fonts/geomanist/geomanist-black-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-black-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-black-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-black-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-black-italic.svg#geomanist) format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-ultra.eot);
  src: url(../fonts/geomanist/geomanist-ultra.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-ultra.woff2) format("woff2"), url(../fonts/geomanist/geomanist-ultra.woff) format("woff"), url(../fonts/geomanist/geomanist-ultra.ttf) format("truetype"), url(../fonts/geomanist/geomanist-ultra.svg#geomanist) format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-ultra-italic.eot);
  src: url(../fonts/geomanist/geomanist-ultra-italic.eot?#iefix) format("embedded-opentype"), url(../fonts/geomanist/geomanist-ultra-italic.woff2) format("woff2"), url(../fonts/geomanist/geomanist-ultra-italic.woff) format("woff"), url(../fonts/geomanist/geomanist-ultra-italic.ttf) format("truetype"), url(../fonts/geomanist/geomanist-ultra-italic.svg#geomanist) format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Light.otf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Regular.otf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Medium.otf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Black.otf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'uniform';
  src: url(../fonts/uniform/Uniform-Ultra.otf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Light.otf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Regular.otf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Medium.otf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Black.otf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'uniformCondensed';
  src: url(../fonts/uniform/UniformCondensed-Ultra.otf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Light.otf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Regular.otf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Medium.otf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Black.otf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'uniformExtraCondensed';
  src: url(../fonts/uniform/UniformExtraCondensed-Ultra.otf);
  font-weight: 900;
  font-style: italic;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden, .front .l-featured .block-ds-extras .field-name-title h2 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.l-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.l-container:after {
  content: " ";
  display: block;
  clear: both;
}

.menu, ul.menu li, .nav-social ul.menu li {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
}

.l-highlighted .view-id-contact_per_node .view-content .views-row .phone:before, .l-highlighted .view-id-contact_per_node .view-content .views-row .email:before, .nav-social #twitter:before, .nav-social #facebook:before, .nav-social #youtube:before, .nav-social #instagram:before, .nav-social #home:before, .nav-social #search:before, .nav-social #google:before, .nav-social #vimeo:before, .nav-social #rss:before, .nav-social #contact:before, .b-addthis #facebook:before, .b-addthis #twitter:before, .b-organizaciones h3.title:before, .b-agenda .view-content .views-row .date:before, .b-contactos .view-content .views-row .phone a:before, .b-contactos .view-content .views-row .email a:before, .b-catalogos h3.title:before, .page-catalogos h3.title:before, .alt-order-rows .views-row .text .more a:after, .b-producciones .text .more a:after, .slick--view--frontpage-slideshow .slide__content .slide__caption .slide__link a:after, .l-content .block-views .more-link:after, .l-content .block-views .views-more-link:after, .front .l-content .block-views .more-link:after, .front .l-content .block-views .views-more-link:after, .nav-social ul.menu li {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  zoom: 1;
}

.l-branding h1.site-name a, .b-logo-mcj .block-content a, .b-logo-presidencia .block-content a {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
}

.l-highlighted .view-id-contact_per_node .view-content .views-row .phone:before, .l-highlighted .view-id-contact_per_node .view-content .views-row .email:before, .nav-social #twitter:before, .nav-social #facebook:before, .nav-social #youtube:before, .nav-social #instagram:before, .nav-social #home:before, .nav-social #search:before, .nav-social #google:before, .nav-social #vimeo:before, .nav-social #rss:before, .nav-social #contact:before, .b-addthis #facebook:before, .b-addthis #twitter:before, .b-organizaciones h3.title:before, .b-agenda .view-content .views-row .date:before, .b-contactos .view-content .views-row .phone a:before, .b-contactos .view-content .views-row .email a:before, .b-catalogos h3.title:before, .page-catalogos h3.title:before {
  vertical-align: baseline !important;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
  speak: none;
  line-height: 1em;
  width: 1em;
}

.alt-order-rows .views-row .text .more a:after, .b-producciones .text .more a:after, .slick--view--frontpage-slideshow .slide__content .slide__caption .slide__link a:after, .l-content .block-views .more-link:after, .l-content .block-views .views-more-link:after, .front .l-content .block-views .more-link:after, .front .l-content .block-views .views-more-link:after {
  vertical-align: baseline !important;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
  speak: none;
  line-height: 1em;
  width: 1em;
}

html {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "uniform", helvetica, arial, sans-serif;
  font-weight: 300;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "uniform", helvetica, arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1.2;
}

h1 {
  font-size: 30px;
  font-size: 2rem;
}

h2 {
  font-size: 22.5px;
  font-size: 1.5rem;
}

h3 {
  font-size: 18.75px;
  font-size: 1.25rem;
}

h4 {
  font-size: 15px;
  font-size: 1rem;
}

h5 {
  font-size: 12px;
  font-size: 0.8rem;
}

h6 {
  font-size: 9px;
  font-size: 0.6rem;
}

b, strong, label {
  font-weight: 500;
}

small {
  font-size: 80%;
}

blockquote, q {
  quotes: none;
  margin: 0;
  font-style: italic;
}

span.field-label,
.label-inline {
  float: left;
  font-weight: 700;
}

span.field-label:before,
.label-inline:before {
  content: "• ";
}

a:link, a:visited, a:active, li a.active {
  transition: all 250ms ease-in-out;
  text-decoration: none;
  color: #f27e8b;
  border: none;
  background-color: transparent;
}

a:link:hover, a:link:active, a:link:focus, a:visited:hover, a:visited:active, a:visited:focus, a:active:hover, a:active:active, a:active:focus, li a.active:hover, li a.active:active, li a.active:focus {
  color: #ed5061;
  outline: 0;
}

.field.field-name-body {
  text-align: justify;
}

.paragraphs-item-paragraph-text {
  padding: 0px !important;
  text-align: justify;
}

.element-invisible {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

/**
 * LISTS
 **********************************************/
.menu a, ul.menu li a {
  display: block;
}

/**
 * FORMS
 **********************************************/
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="number"],
textarea,
.recaptcha_input_area input[type="text"] {
  box-sizing: border-box;
  max-width: 100%;
  padding: 2px 0px 2px 5px;
  border: 1px solid #46464b !important;
  border-radius: 2px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
textarea:focus,
.recaptcha_input_area input[type="text"]:focus {
  outline: none;
}

input[type="submit"] {
  font-size: 14px;
  font-size: 0.93333rem;
  margin: 0;
  padding: 6px 8px;
  box-shadow: none;
  border: none !important;
  border-radius: 2px;
  transition: all 250ms ease-in-out;
  background: #111;
  color: #fff;
}

input[type="submit"]:hover {
  cursor: pointer;
  background: #ffde2f;
  color: #111;
}

.resizable-textarea .grippie {
  border: 0;
  background: none;
}

fieldset.captcha {
  padding: 0;
  border: 0;
}

fieldset.captcha img#recaptcha_logo,
fieldset.captcha .fieldset-legend {
  display: none;
}

fieldset.captcha .fieldset-description {
  padding: 10px 0;
}

.views-exposed-widgets {
  margin: 0;
}

.views-exposed-form .views-exposed-widget {
  padding: 0;
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin: 0;
}

.node-type-webform .recaptchatable,
.node-type-webform .recaptchatable #recaptcha_image {
  border: 0 !important;
}

img, media {
  max-width: 100%;
  height: auto;
}

figure {
  position: relative;
  margin: 0;
  padding: 0;
}

figure img {
  display: block;
  width: 100%;
}

.search-form .form-type-searchfield,
.block-search .form-type-searchfield {
  max-width: 85%;
}

.search-form input[type="text"],
.block-search input[type="text"] {
  padding: 0;
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
}

.search-form input[type="submit"],
.block-search input[type="submit"] {
  font-family: "fontello";
  margin: 0;
  padding: 0;
  background-image: url(../img/search.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ffde2f;
  color: transparent !important;
  border-radius: 3px 3px 3px 0px;
}

.l-content .search-form input[type="submit"], .l-content
.block-search input[type="submit"] {
  position: relative;
  top: -18px;
}

.search-form input[type="submit"]:hover,
.block-search input[type="submit"]:hover {
  opacity: 0.3;
}

.search-form input[type="search"],
.block-search input[type="search"] {
  background: transparent;
  border-radius: 0px;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid black !important;
  min-width: 30vw;
}

.l-content .search-form input[type="search"], .l-content
.block-search input[type="search"] {
  color: #fff;
  border-bottom: 1px solid #ffde2f !important;
}

@media screen and (max-width: 768px) {
  .search-form input[type="search"],
  .block-search input[type="search"] {
    min-width: 25vw;
  }
}

.search-form .container-inline,
.block-search .container-inline {
  display: flex;
  align-items: flex-end;
}

.search-form .container-inline .form-item-search-block-form,
.block-search .container-inline .form-item-search-block-form {
  flex: 1;
  margin: 0px;
}

.block-search {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 680px) {
  .block-search {
    display: none;
  }
}

.block-search .block-content {
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
}

.block-search .block-content input[type="search"] {
  background: transparent;
  border-radius: 0px;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid black !important;
  min-width: 260px;
}

@media screen and (max-width: 768px) {
  .block-search .block-content input[type="search"] {
    min-width: 25vw;
  }
}

.block-search .block-content input[type="submit"] {
  background-image: url(../img/search.png);
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
}

.block-search .block-content input[type="submit"]:hover {
  opacity: 0.3;
}

.search-form {
  margin: 0px auto 0px 0;
  padding-top: 5px;
  padding-bottom: 10px;
}

.search-form .search-advanced {
  display: none;
}

.search-form label {
  display: none;
}

.search-form input[type="text"] {
  font-size: 32px;
  font-size: 2.13333rem;
  height: 40px;
  background: none;
  min-width: 240px;
  color: #000;
  border-color: #000 !important;
  background: transparent;
}

.search-form input[type="submit"] {
  width: 40px;
  height: 40px;
  font-size: 24px;
  font-size: 1.6rem;
  color: #000;
}

.search-form input[type="submit"]:hover {
  color: #f27e8b;
}

.search-form ::-webkit-input-placeholder {
  color: #000;
}

.search-form :-moz-placeholder {
  color: #000;
  opacity: 1;
}

.search-form ::-moz-placeholder {
  color: #000;
  opacity: 1;
}

.search-form :-ms-input-placeholder {
  color: #000;
}

.block-search {
  float: right;
  text-align: center;
}

.block-search input[type="text"] {
  min-width: 95%;
  color: #000;
  border-width: 0 0 1px 0 !important;
  border-color: #000 !important;
  border-radius: 0 !important;
  background: transparent;
}

.block-search input[type="submit"] {
  margin: 0 0 -30px 0;
  width: 24px;
  height: 24px;
  color: #000;
}

.block-search input[type="submit"]:hover {
  color: #fff;
}

.block-search ::-webkit-input-placeholder {
  color: #000;
}

.block-search :-moz-placeholder {
  color: #000;
  opacity: 1;
}

.block-search ::-moz-placeholder {
  color: #000;
  opacity: 1;
}

.block-search :-ms-input-placeholder {
  color: #000;
}

.page-search h1.page-title {
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-size: 40px;
  font-size: 2.66667rem;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 5px;
}

.search-result {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #46464b;
}

.search-result strong {
  color: yellow;
}

.search-result h3.title {
  font-size: 24px;
  font-size: 1.6rem;
  margin: 0;
  font-weight: normal;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.l-page {
  background-color: #261b29;
}

.l-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: visible;
}

.l-main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.l-content {
  order: 2;
}

.l-sidebar-first {
  order: 1;
}

.l-sidebar-second {
  order: 3;
}

@media (min-width: 768px) {
  .l-main {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .l-content {
    flex: 66.10169%;
    order: 1;
  }
  .l-sidebar-first,
  .l-sidebar-second {
    flex: 0 1 32.20339%;
    margin-left: auto;
  }
}

@media (min-width: 960px) {
  .l-main {
    flex-wrap: nowrap;
  }
  .l-content {
    flex: 74.57627%;
    order: 2;
  }
  .l-sidebar-first,
  .l-sidebar-second {
    flex: 0 1 23.72881%;
  }
}

.l-container-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.l-header {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: visible;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin-right: -100%;
  margin-left: -100%;
  padding-right: 100%;
  padding-left: 100%;
  background-color: #ffde2f;
  border-top: 2px solid #f4ff73;
  flex: 100%;
  max-width: 960px;
}

.l-header-region {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 400px) {
  .l-header-region {
    justify-content: center;
  }
}

.l-branding h1.site-name {
  margin: 0;
}

.l-branding h1.site-name a {
  background-size: 100%;
  display: block;
  background-image: url(../img/logo.svg);
  background-repeat: no-repeat;
  width: 92px;
  height: 30px;
  background-position: 0px 0px;
}

.no-svg .l-branding h1.site-name a {
  background-image: url(../img/logo.png);
}

.b-logo-mcj .block-content,
.b-logo-presidencia .block-content,
.l-branding .block-content {
  border-left: 1px solid #111;
  margin: 10px 0 10px 0px;
  padding: 0 12px 0 12px;
}

.b-logo-mcj .block-content a,
.b-logo-presidencia .block-content a,
.l-branding .block-content a {
  background-size: 100%;
  filter: grayscale(1);
}

.b-logo-mcj .block-content a {
  display: block;
  background-image: url(../img/logo-mcj-plain.svg);
  background-repeat: no-repeat;
  width: 48px;
  height: 30px;
  background-position: 0px 0px;
}

.no-svg .b-logo-mcj .block-content a {
  background-image: url(../img/logo-mcj-plain.png);
}

.b-logo-presidencia .block-content {
  border-left: 0px solid #111;
}

.b-logo-presidencia .block-content a {
  display: block;
  background-image: url(../img/logo-bicentenario.svg);
  background-repeat: no-repeat;
  width: 64px;
  height: 32px;
}

.no-svg .b-logo-presidencia .block-content a {
  background-image: url(../img/logo-bicentenario.png);
}

.l-featured {
  background-size: cover;
  width: 100% !important;
}

.l-featured .field-name-field-img-header, .l-featured .field-name-field-img-main {
  background-size: cover;
  width: 100% !important;
}

.l-featured .block-ds-extras .block-content {
  display: flex;
  justify-content: center;
}

.l-featured .block-ds-extras .field-name-field-img-header img, .l-featured .block-ds-extras .field-name-field-img-main img {
  width: 100%;
  max-width: 100%;
  height: auto;
  opacity: 0;
}

.l-featured .block-ds-extras .field-name-title {
  position: absolute;
  align-self: center !important;
  transform-origin: center center;
  margin-top: 0px;
}

.l-featured .block-ds-extras .field-name-title h2 {
  font-size: 40px;
  font-size: 2.66667rem;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border: 2px solid;
  padding: 10px;
}

.l-highlighted {
  margin: 20px 0;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-size: 0.8rem;
  background-color: #ffde2f;
  border: 8px solid #fff;
  align-items: center;
  margin-bottom: 5px;
  flex-wrap: wrap;
}

.l-highlighted .b-addthis {
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
}

.l-highlighted .b-addthis a {
  color: #111;
  margin-bottom: 0px;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .l-highlighted .contextual-links-region {
    width: 100%;
  }
}

.l-highlighted .view-id-contact_per_node .view-content .views-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.l-highlighted .view-id-contact_per_node .view-content .views-row .phone:before {
  content: "";
  text-align: center;
}

.l-highlighted .view-id-contact_per_node .view-content .views-row .email:before {
  content: "";
  text-align: center;
}

.l-highlighted .view-id-contact_per_node .view-content .views-row .name-rol, .l-highlighted .view-id-contact_per_node .view-content .views-row .phone, .l-highlighted .view-id-contact_per_node .view-content .views-row .email {
  margin: 0 8px;
}

.l-highlighted .view-id-contact_per_node .view-content .views-row .name-rol:before, .l-highlighted .view-id-contact_per_node .view-content .views-row .phone:before, .l-highlighted .view-id-contact_per_node .view-content .views-row .email:before {
  color: #000;
  margin-right: 4px;
}

.l-highlighted .view-id-contact_per_node .view-content .views-row .name-rol a, .l-highlighted .view-id-contact_per_node .view-content .views-row .phone a, .l-highlighted .view-id-contact_per_node .view-content .views-row .email a {
  color: #000;
}

.l-highlighted .view-id-contact_per_node .view-content .views-row .name-rol a:hover, .l-highlighted .view-id-contact_per_node .view-content .views-row .phone a:hover, .l-highlighted .view-id-contact_per_node .view-content .views-row .email a:hover {
  color: #f27e8b;
}

@media screen and (max-width: 768px) {
  .l-highlighted .view-id-contact_per_node .view-content .views-row .name-rol, .l-highlighted .view-id-contact_per_node .view-content .views-row .phone, .l-highlighted .view-id-contact_per_node .view-content .views-row .email {
    width: 100%;
    text-align: center;
  }
}

.node-type-audio .view-mode-full,
.node-type-document .view-mode-full,
.node-type-gallery .view-mode-full,
.node-type-video .view-mode-full,
.node-type-webform .view-mode-full,
.node-type-article .view-mode-full,
.node-type-event .view-mode-full,
.node-type-organization .view-mode-full,
.node-type-program .view-mode-full,
.node-type-project .view-mode-full,
.node-type-produccion .view-mode-full {
  position: relative;
  padding: 20px;
  background: #fff;
  color: #111;
}

.node-type-audio .view-mode-full h2.page-title,
.node-type-document .view-mode-full h2.page-title,
.node-type-gallery .view-mode-full h2.page-title,
.node-type-video .view-mode-full h2.page-title,
.node-type-webform .view-mode-full h2.page-title,
.node-type-article .view-mode-full h2.page-title,
.node-type-event .view-mode-full h2.page-title,
.node-type-organization .view-mode-full h2.page-title,
.node-type-program .view-mode-full h2.page-title,
.node-type-project .view-mode-full h2.page-title,
.node-type-produccion .view-mode-full h2.page-title {
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-size: 40px;
  font-size: 2.66667rem;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #261b29;
}

.node-type-audio .view-mode-full .field-name-field-img-main img,
.node-type-document .view-mode-full .field-name-field-img-main img,
.node-type-gallery .view-mode-full .field-name-field-img-main img,
.node-type-video .view-mode-full .field-name-field-img-main img,
.node-type-webform .view-mode-full .field-name-field-img-main img,
.node-type-article .view-mode-full .field-name-field-img-main img,
.node-type-event .view-mode-full .field-name-field-img-main img,
.node-type-organization .view-mode-full .field-name-field-img-main img,
.node-type-program .view-mode-full .field-name-field-img-main img,
.node-type-project .view-mode-full .field-name-field-img-main img,
.node-type-produccion .view-mode-full .field-name-field-img-main img {
  width: 100%;
}

.node-type-organization .view-mode-full {
  padding: 50px 20px 20px 20px;
}

.view-info-per-node {
  overflow: hidden;
  padding: 20px;
  float: right;
  max-width: 50%;
  background-color: #ffde2f;
  border: 8px solid #fff;
  color: #111;
}

.view-info-per-node h2.title {
  text-align: center;
  text-transform: uppercase;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
}

.view-info-per-node h2.title:before {
  content: '';
  width: 100%;
  height: 90px;
  background: url("/sites/all/themes/raiz/img/logo.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: -30px 0px 10px 0px;
  opacity: 0.1;
}

@media (min-width: 768px) {
  .view-info-per-node {
    max-width: 50%;
    float: right;
    margin-left: 10px;
  }
}

.node-type-article h2.page-title {
  background: #261b29;
  color: #fff !important;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px !important;
  padding-top: 20px;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 48px;
  font-size: 3.2rem;
  line-height: 40px;
  text-align: center;
}

.node-type-article .field-name-field-txt-bajada {
  background: #261b29;
  color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px !important;
  font-weight: 300;
  font-size: 13px;
  font-size: 0.86667rem;
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  margin-bottom: -30px !important;
}

.node-type-article .field-name-post-date {
  background: #261b29;
  color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px !important;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.86667rem;
  text-align: center;
  letter-spacing: 2px;
  padding-top: 14px;
  padding-bottom: 20px;
}

.node-type-article .field-name-post-date:before {
  content: '';
  height: 2px;
  width: 200px;
  display: block;
  position: relative;
  background: linear-gradient(to right, #f37d8d 0%, #f37d8d 50%, #fff 50%, #fff 100%);
  margin: 16px auto;
}

.node-type-article .field-name-field-img-main {
  margin-top: 20px;
}

.node-type-article .field-name-field-img-footer {
  font-size: 14px;
  font-size: 0.93333rem;
  font-style: italic;
}

.node-type-article .field-name-field-tax-tags {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 16px;
  font-size: 1.06667rem;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  margin-top: 10px;
}

.node-type-event .view-mode-full {
  display: flex;
  flex-wrap: wrap;
}

.node-type-event .view-mode-full .field-name-title {
  flex: 1 1 100%;
}

.node-type-event .view-mode-full .field-name-title h2.page-title {
  background: #261b29;
  color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px !important;
  padding-top: 20px;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 48px;
  font-size: 3.2rem;
  line-height: 40px;
  text-align: center;
  padding-bottom: 14px;
}

.node-type-event .view-mode-full .field-name-field-txt-bajada {
  background: #261b29;
  color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px !important;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 13px;
  font-size: 0.86667rem;
  text-align: center;
  text-transform: uppercase;
}

.node-type-event .view-mode-full .field-name-field-img-main {
  flex: 1 1 50%;
}

@media screen and (max-width: 768px) {
  .node-type-event .view-mode-full .field-name-field-img-main {
    flex: 1 1 100%;
  }
}

.node-type-event .view-mode-full .group-info {
  flex: 1 1 50%;
  padding: 1% 2%;
}

@media screen and (max-width: 768px) {
  .node-type-event .view-mode-full .group-info {
    flex: 1 1 100%;
  }
}

.node-type-event .view-mode-full .group-info .field {
  margin-bottom: 10px;
  font-weight: 100;
  font-size: 18px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
}

.node-type-event .view-mode-full .group-info .field .label-above {
  font-weight: 900;
  font-size: 13px;
  font-size: 0.86667rem;
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
}

.node-type-project .field-name-title {
  flex: 1 1 100%;
}

.node-type-project .field-name-title h2.page-title {
  background: #261b29;
  color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px !important;
  padding-top: 20px;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 48px;
  font-size: 3.2rem;
  line-height: 40px;
  text-align: center;
  padding-bottom: 14px;
}

.node-type-project .group-info {
  flex: 1 1 50%;
}

@media screen and (max-width: 768px) {
  .node-type-project .group-info {
    flex: 1 1 100%;
  }
}

.node-type-project .group-info .field {
  margin-bottom: 10px;
  font-weight: 100;
  font-size: 18px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
}

.node-type-project .group-info .field .label-above {
  font-weight: 900;
  font-size: 13px;
  font-size: 0.86667rem;
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
}

.node-type-video .l-highlighted, .node-type-audio .l-highlighted, .node-type-gallery .l-highlighted, .node-type-document .l-highlighted {
  display: none;
}

.node-type-video .field-name-title, .node-type-audio .field-name-title, .node-type-gallery .field-name-title, .node-type-document .field-name-title {
  flex: 1 1 100%;
}

.node-type-video .field-name-title h2.page-title, .node-type-audio .field-name-title h2.page-title, .node-type-gallery .field-name-title h2.page-title, .node-type-document .field-name-title h2.page-title {
  background: #261b29;
  color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px !important;
  margin-bottom: 20px;
  padding-top: 20px;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 48px;
  font-size: 3.2rem;
  line-height: 40px;
  text-align: center;
  padding-bottom: 14px;
}

.node-type-video .field-name-field-txt-bajada, .node-type-audio .field-name-field-txt-bajada, .node-type-gallery .field-name-field-txt-bajada, .node-type-document .field-name-field-txt-bajada {
  background: #261b29;
  color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px !important;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 13px;
  font-size: 0.86667rem;
  text-align: center;
  text-transform: uppercase;
}

.page-node h2.page-title, .page-node h1.page-title, .page-taxonomy h2.page-title, .page-taxonomy h1.page-title, .page-catalogos h2.page-title, .page-catalogos h1.page-title {
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 48px;
  font-size: 3.2rem;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
}

.node-type-contact .field-name-field-img-main {
  text-align: center;
}

.node-type-contact .field-name-field-img-main img {
  width: 160px;
  margin: 50px auto 0px;
  border-radius: 5000px;
}

.l-footer {
  padding-top: 20px;
  border-top: 1px solid #3b3540;
  display: flex;
  flex-direction: column;
}

.l-nav {
  max-width: 100vw !important;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  left: 0px;
  right: 0px;
  position: relative;
  align-content: flex-end;
  justify-content: flex-end;
  min-height: 50px;
  flex: 1;
  margin-right: 10px;
}

@media screen and (max-width: 400px) {
  .l-nav {
    flex: 1 1 100%;
    width: 100%;
    justify-content: center;
  }
}

.l-header .nav-main {
  z-index: 999;
  align-self: center;
  position: relative;
}

.l-header .nav-main h2 {
  background: transparent;
  font-family: "uniform", helvetica, arial, sans-serif;
  font-size: 15px;
  font-size: 1rem;
  font-weight: 800;
  color: #000;
  cursor: pointer;
  margin: 0;
  padding: 4px 10px;
  font-size: 12px;
  font-size: 0.8rem;
  text-transform: uppercase;
  border: 1px solid black;
  display: flex;
  align-content: center;
  align-items: center;
  transition: all 0.3s;
}

.l-header .nav-main h2:after {
  margin-left: 40px;
}

.l-header .nav-main h2:hover div.icon {
  width: 15px;
  margin-left: 41px;
}

.l-header .nav-main h2:hover div.icon:before {
  width: 15px;
  margin-left: 0px;
}

.l-header .nav-main h2:hover div.icon:after {
  width: 15px;
}

.l-header .nav-main h2 div.icon {
  background: black;
  height: 3px;
  width: 11px;
  margin-left: 45px;
  position: relative;
  float: right;
  right: 0px;
  transition: all 0.3s;
}

.l-header .nav-main h2 div.icon:before {
  content: '';
  background: black;
  height: 3px;
  width: 15px;
  top: -4px;
  position: absolute;
  margin-left: -4px;
  transition: all 0.3s;
}

.l-header .nav-main h2 div.icon:after {
  content: '';
  background: black;
  height: 3px;
  width: 11px;
  top: 4px;
  position: absolute;
  transition: all 0.3s;
}

.l-header .nav-main ul.menu {
  display: none;
  background: #ffde2f;
  position: absolute;
  padding: 3vh 10px;
  right: 0px;
  top: 36px;
  border-left: 1px solid #f4ff73;
  border-right: 1px solid #f4ff73;
}

@media screen and (max-width: 400px) {
  .l-header .nav-main ul.menu {
    right: -30px;
    left: -30px;
  }
}

.l-header .nav-main ul.menu .menu-logo {
  text-align: center;
  margin-top: -3vh;
  opacity: 0.3;
}

.l-header .nav-main ul.menu .close-menu {
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 700;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  font-size: 10px;
  font-size: 0.66667rem;
  color: black;
  margin-top: 10px;
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -3vh;
  letter-spacing: 4px;
}

.l-header .nav-main ul.menu .close-menu:hover {
  letter-spacing: 0px;
}

.l-header .nav-main ul.menu li {
  padding: 10px 20px;
  font-size: 13px;
  font-size: 0.86667rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
  text-align: center;
}

.l-header .nav-main ul.menu li a {
  color: #111;
  letter-spacing: 1px;
  font-weight: 900;
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
}

.l-header .nav-main ul.menu li a:hover {
  color: #f27e8b;
}

.l-header .nav-main ul.menu li.last {
  border-bottom: none;
}

nav.admin-tabs ul.primary {
  border-color: #ffde2f;
  margin-top: 20px;
}

nav.admin-tabs ul.primary li a {
  background: #ffde2f;
  border-radius: 2px 2px 0px 0px;
  border-color: #ffde2f;
  background: #ffde2f;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  padding: 3px 10px 0px;
  color: #261b29;
}

nav.admin-tabs ul.primary li a:hover {
  opacity: 0.6;
}

nav.admin-tabs ul.primary li.active a {
  border-bottom: 1px solid #ffde2f;
  opacity: 0.6;
}

.nav-quick-links {
  margin: 20px 0;
}

.nav-quick-links ul.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nav-quick-links ul.menu li {
  flex: 1 1 50px;
}

.nav-quick-links ul.menu li {
  margin: 4px;
  padding: 8px;
  font-size: 12px;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-align: center;
  background: #111;
}

.nav-quick-links ul.menu li a {
  color: #fff;
}

.nav-quick-links ul.menu li a:hover {
  color: #f27e8b;
}

@media (min-width: 960px) {
  .nav-quick-links ul.menu li.first {
    margin-left: 0;
  }
  .nav-quick-links ul.menu li.last {
    margin-right: 0;
  }
}

.l-footer .nav-quick-links {
  border-top: 1px solid #3b3540;
  border-bottom: 1px solid #3b3540;
}

.l-footer .nav-quick-links ul.menu li {
  background: transparent;
}

.l-footer .nav-quick-links ul.menu li a {
  color: #fff;
  font-size: 9px;
  font-size: 0.6rem;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}

.l-footer .nav-quick-links ul.menu li a:hover {
  color: #f27e8b;
}

.nav-social {
  margin: 20px auto;
}

.nav-social ul.menu li {
  padding: 0 10px;
}

.nav-social #google,
.nav-social #vimeo,
.nav-social #rss,
.nav-social #contact,
.nav-social #home,
.nav-social #search,
.nav-social #twitter,
.nav-social #facebook,
.nav-social #youtube,
.nav-social #instagram {
  width: 36px;
  height: 36px;
  font-size: 0;
  line-height: 0;
  text-align: center;
  background-color: #fff;
  color: #111;
  border-radius: 100%;
}

.nav-social #google:before,
.nav-social #vimeo:before,
.nav-social #rss:before,
.nav-social #contact:before,
.nav-social #home:before,
.nav-social #search:before,
.nav-social #twitter:before,
.nav-social #facebook:before,
.nav-social #youtube:before,
.nav-social #instagram:before {
  font-size: 14px;
  font-size: 0.93333rem;
  line-height: 36px;
}

.nav-social #twitter:before {
  content: "";
  text-align: center;
}

.nav-social #twitter:hover {
  background-color: #55acee;
}

.nav-social #facebook:before {
  content: "";
  text-align: center;
}

.nav-social #facebook:hover {
  background-color: #3b5998;
}

.nav-social #youtube:before {
  content: "";
  text-align: center;
}

.nav-social #youtube:hover {
  background-color: #b00;
}

.nav-social #instagram:before {
  content: "";
  text-align: center;
}

.nav-social #instagram:hover {
  background-color: #517fa4;
}

.nav-social #home:before {
  content: "";
  text-align: center;
}

.nav-social #home:hover {
  background-color: #ffde2f;
}

.nav-social #search:before {
  content: "";
  text-align: center;
}

.nav-social #search:hover {
  background-color: #ffde2f;
}

.nav-social #google:before {
  content: "";
  text-align: center;
}

.nav-social #google:hover {
  background-color: #dd4b39;
}

.nav-social #vimeo:before {
  content: "";
  text-align: center;
}

.nav-social #vimeo:hover {
  background-color: #aad450;
}

.nav-social #rss:before {
  content: "";
  text-align: center;
}

.nav-social #rss:hover {
  background-color: #ffde2f;
}

.nav-social #contact:before {
  content: "";
  text-align: center;
}

.nav-social #contact:hover {
  background-color: #ffde2f;
}

.b-addthis {
  display: flex;
  text-align: center;
}

.b-addthis span {
  font-size: 0;
  visibility: hidden;
  line-height: 0;
}

.b-addthis #facebook,
.b-addthis #twitter,
.b-addthis #google,
.b-addthis #email {
  font-size: 20px;
  font-size: 1.33333rem;
  width: 32px;
  height: 32px;
  line-height: 32px;
  color: #111;
}

.b-addthis #facebook:before {
  content: "";
  text-align: center;
}

.b-addthis #facebook:hover {
  color: #3b5998;
}

.b-addthis #twitter:before {
  content: "";
  text-align: center;
}

.b-addthis #twitter:hover {
  color: #55acee;
}

.b-footer {
  font-size: 8px;
  font-size: 0.53333rem;
  text-transform: uppercase;
  padding: 14px;
  text-align: center;
  color: #fff;
  letter-spacing: 4px;
}

.b-mailchimp-signup {
  font-family: "uniform", helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 0.8rem;
  margin: 20px auto;
}

.b-mailchimp-signup #mc_embed_signup_scroll {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .b-mailchimp-signup #mc_embed_signup_scroll {
    justify-content: space-between;
    text-align: center;
  }
}

.b-mailchimp-signup label {
  margin-right: 20px;
  line-height: 28px;
  font-weight: 900;
  font-family: "uniform", helvetica, arial, sans-serif;
  font-size: 10px;
  font-size: 0.66667rem;
  text-transform: uppercase;
  color: #4588d5;
}

@media screen and (max-width: 768px) {
  .b-mailchimp-signup label {
    width: 100%;
  }
}

.b-mailchimp-signup input[type="email"],
.b-mailchimp-signup input[type="submit"] {
  font-family: "geomanist", helvetica, arial, sans-serif !important;
  border: none !important;
  border-radius: 0;
}

@media screen and (max-width: 768px) {
  .b-mailchimp-signup input[type="email"],
  .b-mailchimp-signup input[type="submit"] {
    width: 100%;
    min-height: 28px;
    text-align: center;
  }
}

.b-mailchimp-signup input[type="email"] {
  padding: 2px 20px 2px 20px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .b-mailchimp-signup .clear {
    width: 100%;
  }
}

.b-mailchimp-signup input[type="submit"] {
  font-weight: 800;
  letter-spacing: 2px;
  font-family: "uniform", helvetica, arial, sans-serif;
  font-size: 10px;
  font-size: 0.66667rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff !important;
  padding: 8px 30px 6px;
}

.b-mailchimp-signup input[type="submit"]:hover {
  background: #ffde2f;
  color: #000;
}

.node-grid {
  margin: 10px 0;
  padding: 20px 0;
}

.node-grid .view-content {
  display: flex;
  flex-wrap: wrap;
}

.node-grid .view-content .views-row {
  flex: 1 0 300px;
  margin-bottom: 20px;
  padding: 0 15px;
}

@media screen and (min-width: 768px) {
  .node-grid .view-content .views-row {
    max-width: 50%;
  }
}

@media screen and (min-width: 960px) {
  .node-grid .view-content .views-row {
    max-width: 33%;
  }
}

.node-grid .view-events .views-row:nth-of-type(3n+2) {
  border-right: 1px dotted #46464b;
  border-left: 1px dotted #46464b;
}

.node-grid a {
  color: #fff;
}

.node-grid a:hover {
  color: #f27e8b;
}

.node-grid img {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.node-grid span {
  font-size: 12px;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.node-grid span.label, .node-grid span.date {
  padding: 2px 26px;
}

.node-grid span.label {
  background: #111;
  color: #fff;
}

.node-grid span.date {
  background: #ffde2f;
}

.node-grid h2.title {
  font-size: 18px;
  font-size: 1.2rem;
}

.alt-order-rows .views-row {
  flex-direction: column;
  margin: 40px 0;
  display: flex;
  background: #097beb;
}

.alt-order-rows .views-row .text, .alt-order-rows .views-row .media {
  flex: 1 1 100%;
}

.alt-order-rows .views-row .text {
  padding: 20px 40px;
  text-transform: uppercase;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.alt-order-rows .views-row .text .type {
  color: #f9f608;
  line-height: 18px;
  margin-bottom: 4px;
}

.alt-order-rows .views-row .text h3.title {
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 40px;
  font-size: 2.66667rem;
  line-height: 40px;
}

.alt-order-rows .views-row .text h3.title a {
  color: #87df69;
}

.alt-order-rows .views-row .text h3.title a:hover {
  color: #65d63f;
}

.alt-order-rows .views-row .text .more a {
  padding: 8px 16px 7px;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 12px;
  font-family: "uniform", helvetica, arial, sans-serif;
  line-height: 12px;
  letter-spacing: 2px;
  border: 1px solid #fff;
  font-size: 12.5px;
  font-size: 0.83333rem;
}

.alt-order-rows .views-row .text .more a:after {
  content: "";
  text-align: center;
}

.alt-order-rows .views-row .text .more a:after {
  margin-left: 5px;
  font-weight: 900;
}

.alt-order-rows .views-row .text .more a:hover {
  background: #fff;
  color: #ed5061;
}

.alt-order-rows .views-row .media img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.alt-order-rows .views-row:nth-child(2n) {
  background: #f37d8d;
}

.alt-order-rows .views-row:nth-child(2n) .media {
  order: 2;
}

@media (min-width: 768px) {
  .alt-order-rows .views-row {
    flex-direction: row;
  }
  .alt-order-rows .views-row .text, .alt-order-rows .views-row .media {
    flex: 1 1 50%;
  }
}

.b-producciones {
  padding-top: 40px;
}

.b-producciones form#views-exposed-form-productions-block {
  margin-bottom: 16px;
}

.b-producciones form#views-exposed-form-productions-block label {
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 900;
  padding: 0px 8px;
  font-size: 12px;
  font-size: 0.8rem;
}

.b-producciones form#views-exposed-form-productions-block select {
  border-radius: 0px;
  -webkit-appearance: none;
  border: 0px;
  padding: 4px 8px;
  margin: 4px 8px;
  color: #ffde2f;
  background: transparent;
  border-bottom: 1px solid #ffde2f;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 0.8rem;
}

.b-producciones form#views-exposed-form-productions-block .views-submit-button input {
  margin-top: 17px;
  background: #ffde2f;
  color: #261b29;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
}

.b-producciones form#views-exposed-form-productions-block .views-submit-button input:hover {
  background: #fbd300;
  color: #261b29;
}

.b-producciones .view-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.b-producciones .view-content .views-row {
  position: relative;
  flex: 0 0 100%;
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .b-producciones .view-content .views-row {
    flex: 0 0 50%;
    padding: 0 2%;
  }
}

.b-producciones .info {
  position: absolute;
  top: 0;
  transform: rotate(90deg);
  transform-origin: left top;
}

.b-producciones span {
  font-size: 12px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.b-producciones span.pais, .b-producciones span.lanzamiento {
  padding: 2px 26px;
}

.b-producciones span.pais {
  background: #f27e8b;
  color: #fff;
}

.b-producciones span.lanzamiento {
  background: #ffde2f;
  color: #111;
}

.b-producciones .media {
  flex: 1 1 100%;
  padding: 0 10px 20px;
}

@media screen and (min-width: 1024px) {
  .b-producciones .media {
    flex: 0 0 35%;
  }
}

.b-producciones .media img {
  display: block;
}

.b-producciones .text {
  padding: 0 10px;
  flex: 1 1 100%;
}

@media screen and (min-width: 1024px) {
  .b-producciones .text {
    flex: 0 0 65%;
  }
}

.b-producciones .text .direccion {
  text-transform: uppercase;
  color: #e5f902;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 100;
  font-size: 24px;
  font-size: 1.6rem;
  line-height: 26px;
}

.b-producciones .text h3.title {
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 40px;
  font-size: 2.66667rem;
  line-height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.b-producciones .text .more a {
  padding: 8px 16px 7px;
  background: #097beb;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 12px;
  font-family: "uniform", helvetica, arial, sans-serif;
  line-height: 12px;
  letter-spacing: 2px;
  font-size: 12.5px;
  font-size: 0.83333rem;
}

.b-producciones .text .more a:after {
  content: "";
  text-align: center;
}

.b-producciones .text .more a:after {
  margin-left: 5px;
  font-weight: 900;
}

.b-producciones .text .more a:hover {
  background: #ffde2f;
  color: #000;
}

.slick-loading .slick-list {
  background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
}

@font-face {
  font-family: "slick";
  src: slick-font-url("slick.eot");
  src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

.slick-next {
  right: -25px;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick--view--frontpage-slideshow {
  padding-left: 18px;
  border-left: 2px solid;
  border-image: linear-gradient(to bottom, #e5f902, #e5f902 50%, #097beb 50%, #097beb) 1 100%;
}

.slick--view--frontpage-slideshow .slide__content {
  flex: 1 0 49.15254%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .slick--view--frontpage-slideshow .slide__content {
    flex-direction: row;
  }
  .slick--view--frontpage-slideshow .slide__content .slide__media {
    order: 2;
  }
  .slick--view--frontpage-slideshow .slide__content .slide__caption {
    order: 1;
  }
}

.slick--view--frontpage-slideshow .slide__content .slide__media {
  flex: 1 0 49.15254%;
  margin-bottom: 10px;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1 0 49.15254%;
  padding-right: 10px;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption h2.slide__title {
  flex: 1 100%;
  margin-top: 0px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 35px;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 35px;
  font-size: 2.33333rem;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption .slide__description {
  font-size: 15px;
  font-size: 1rem;
  line-height: 18px;
  flex: 1 100%;
  margin-bottom: 10px;
  color: #cdcdcd;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption .slide__description .slide-type {
  font-size: 11px;
  font-size: 0.73333rem;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #22c6dc;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption .slide__description .slide-event-date {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
  font-size: 0.86667rem;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption .slide__link {
  flex: 1 100%;
  align-self: flex-end;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption .slide__link a {
  padding: 8px 16px 7px;
  background: #097beb;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 12px;
  font-family: "uniform", helvetica, arial, sans-serif;
  line-height: 12px;
  letter-spacing: 2px;
  font-size: 12.5px;
  font-size: 0.83333rem;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption .slide__link a:after {
  content: "";
  text-align: center;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption .slide__link a:after {
  margin-left: 5px;
  font-weight: 900;
}

.slick--view--frontpage-slideshow .slide__content .slide__caption .slide__link a:hover {
  background: #ffde2f;
  color: #000;
}

.slick--view--frontpage-slideshow .slick-dots {
  margin: 0;
  bottom: -40px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

.slick--view--frontpage-slideshow .slick-dots:after {
  margin-left: 5px;
  font-weight: 900;
  line-height: 24px;
}

.slick--view--frontpage-slideshow .slick-dots:before {
  margin-right: 5px;
  font-weight: 900;
  line-height: 24px;
}

.slick--view--frontpage-slideshow .slick-dots li {
  cursor: pointer;
  transition: all 250ms ease-in-out;
  font-size: 0;
  line-height: 0;
  margin: 4px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #fff;
}

.slick--view--frontpage-slideshow .slick-dots li:after {
  content: '0';
  color: rgba(0, 0, 0, 0.2);
  width: 18px;
  height: 18px;
  display: block;
  left: 0px;
  top: 0px;
  font-size: 10px;
  font-size: 0.66667rem;
  font-weight: 700;
  line-height: 20px;
}

.slick--view--frontpage-slideshow .slick-dots li:nth-child(1):after {
  content: '1';
}

.slick--view--frontpage-slideshow .slick-dots li:nth-child(2):after {
  content: '2';
}

.slick--view--frontpage-slideshow .slick-dots li:nth-child(3):after {
  content: '3';
}

.slick--view--frontpage-slideshow .slick-dots li:nth-child(4):after {
  content: '4';
}

.slick--view--frontpage-slideshow .slick-dots li:nth-child(5):after {
  content: '5';
}

.slick--view--frontpage-slideshow .slick-dots li:nth-child(6):after {
  content: '6';
}

.slick--view--frontpage-slideshow .slick-dots li:hover:after, .slick--view--frontpage-slideshow .slick-dots li:focus:after {
  color: black;
}

.slick--view--frontpage-slideshow .slick-dots li.slick-active:after {
  color: black;
}

.b-frontpage-slideshow {
  max-width: 960px;
  margin: auto;
  padding: 40px 20px 20px;
}

.b-noticias .view-content .views-row {
  border-right: 1px dashed #4f425b;
  margin-bottom: 40px;
}

.b-noticias .view-content .views-row:nth-child(3n+3) {
  border-right: 0px dashed #4f425b;
}

.b-noticias .view-content .views-row span.label, .b-noticias .view-content .views-row span.date {
  display: none;
}

.b-noticias .view-content .views-row h2.title {
  margin-top: 0px;
  font-family: "uniform", helvetica, arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.06667rem;
  text-transform: uppercase;
}

.b-noticias .view-content .views-row .lead {
  font-size: 15px;
  font-size: 1rem;
  font-family: "uniform", helvetica, arial, sans-serif;
  font-weight: 300;
  line-height: 20px;
  color: #cdcdcf;
}

.b-noticias .view-content .views-row .views-more-link a {
  color: #fff;
}

.b-noticias .view-content .views-row .views-more-link a:hover {
  color: #ed5061;
}

.l-header-region {
  flex: 5;
}

@media screen and (max-width: 800px) {
  .l-header-region {
    flex: 3;
  }
}

.l-header-region .block-search {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 680px) {
  .l-header-region .block-search {
    display: none;
  }
}

.l-header-region .block-search .block-content {
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
}

.b-programas .view-content .views-row {
  flex: 1 0 100%;
  padding: 0px 20px;
  margin-bottom: 60px;
}

@media screen and (min-width: 768px) {
  .b-programas .view-content .views-row {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

.b-programas .view-content .views-row h2.title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: "uniform", helvetica, arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-size: 1.06667rem;
  text-transform: uppercase;
}

.b-programas .view-content .views-row h2.title a:link, .b-programas .view-content .views-row h2.title a:visited, .b-programas .view-content .views-row h2.title a:active, .b-programas .view-content .views-row h2.title li a.active {
  color: #f27e8b;
}

.b-programas .view-content .views-row h2.title a:link:hover, .b-programas .view-content .views-row h2.title a:link:active, .b-programas .view-content .views-row h2.title a:link:focus, .b-programas .view-content .views-row h2.title a:visited:hover, .b-programas .view-content .views-row h2.title a:visited:active, .b-programas .view-content .views-row h2.title a:visited:focus, .b-programas .view-content .views-row h2.title a:active:hover, .b-programas .view-content .views-row h2.title a:active:active, .b-programas .view-content .views-row h2.title a:active:focus, .b-programas .view-content .views-row h2.title li a.active:hover, .b-programas .view-content .views-row h2.title li a.active:active, .b-programas .view-content .views-row h2.title li a.active:focus {
  color: #ed5061;
  outline: 0;
}

.b-programas .view-content .views-row .lead {
  font-size: 15px;
  font-size: 1rem;
  font-family: "uniform", helvetica, arial, sans-serif;
  font-weight: 300;
  line-height: 20px;
  color: #cdcdcf;
}

.b-programas .view-content .views-row .lead .views-more-link {
  margin-top: 18px;
}

.b-organizaciones .type {
  font-size: 12px;
  font-size: 0.8rem;
  text-transform: lowercase;
}

.b-organizaciones h3.title {
  margin-top: 0px;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  text-transform: uppercase;
}

.b-organizaciones h3.title:before {
  content: "";
  text-align: center;
}

.b-organizaciones h3.title:before {
  margin-right: 4px;
  color: rgba(255, 222, 47, 0.2);
  border: 1px solid rgba(255, 222, 47, 0.1);
  border-radius: 500px;
  padding: 6px;
  width: 35px;
}

.b-organizaciones h3.title a {
  color: #ffde2f;
}

.b-proyectos .view-content {
  align-items: flex-start;
}

.b-proyectos .view-content .views-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.b-proyectos .view-content .views-row figure {
  flex: 1 1 100%;
}

.b-proyectos .view-content .views-row img {
  margin-bottom: 0px;
}

.b-proyectos .view-content .views-row h2.title {
  flex: 1 1 100%;
  font-size: 20px;
  font-size: 1.33333rem;
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  margin-top: 6px;
  font-weight: 900;
  line-height: 24px;
  margin-top: 0px;
  padding: 8px 4px 4px;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
}

.b-proyectos .view-content .views-row span {
  font-size: 12px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 4px 5px 2px;
  line-height: 16px;
  flex: 1 1 100%;
}

.b-proyectos .view-content .views-row span.programa {
  background: rgba(255, 255, 255, 0.1);
  display: block;
  font-weight: 500;
  text-align: center;
  color: #ffde2f;
}

.b-proyectos .view-content .views-row span.date {
  background: rgba(255, 255, 255, 0.15);
  color: #ffde2f;
  text-align: center;
  display: block;
}

.l-content .block-views h2.block-title {
  font-size: 12px;
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "uniform", helvetica, arial, sans-serif;
  margin: 0 0 20px 0;
  padding: 10px 0;
  border-bottom-color: #46464b;
  border-bottom: 1px solid #46464b;
}

.l-content .block-views .more-link, .l-content .block-views .views-more-link {
  display: block;
  font-size: 10px;
  font-size: 0.66667rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "geomanist", helvetica, arial, sans-serif;
  font-weight: 900;
  margin-top: -45px;
  margin-bottom: 45px;
}

.l-content .block-views .more-link:after, .l-content .block-views .views-more-link:after {
  content: "";
  text-align: center;
}

.l-content .block-views .more-link a, .l-content .block-views .views-more-link a {
  color: #fff;
}

.l-content .block-views .more-link:after, .l-content .block-views .views-more-link:after {
  margin-left: 0px;
  font-weight: 900;
}

.l-content .block-views .views-more-link {
  margin-top: 18px;
  margin-bottom: 0px;
  align-self: flex-end;
}

.b-agenda .view-content {
  align-items: flex-start;
}

.b-agenda .view-content .views-row {
  display: flex;
  flex-wrap: wrap;
  transform: scale(1);
  transition: all 0.3s;
}

.b-agenda .view-content .views-row:hover {
  transform: scale(1.05);
}

.b-agenda .view-content .views-row span {
  text-align: center !important;
}

.b-agenda .view-content .views-row .date {
  padding: 0px 5px 0px;
  font-weight: 500;
  color: #261b29;
  flex: 1 1 100%;
  font-size: 18px;
  font-size: 1.2rem;
}

.b-agenda .view-content .views-row .date:before {
  content: "";
  text-align: center;
}

.b-agenda .view-content .views-row .date:before {
  font-size: 16px;
  font-size: 1.06667rem;
  margin-right: 4px;
  color: #261b29;
}

.b-agenda .view-content .views-row .programa {
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 5px 2px;
  font-weight: 700;
  color: #fff;
  flex: 1 1 100%;
  font-size: 11px;
  font-size: 0.73333rem;
}

.b-agenda .view-content .views-row h2.title {
  flex: 1 1 100%;
  font-size: 20px;
  font-size: 1.33333rem;
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  margin-top: 6px;
  font-weight: 900;
  line-height: 24px;
  margin-top: 0px;
  padding: 8px 4px 4px;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
}

.b-agenda .view-content .views-row img {
  margin-bottom: 0px;
  flex: 1 1 100%;
}

.b-agenda .view-content .views-row .type {
  padding: 4px 5px 2px;
  color: #ffde2f;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  flex: 1 1 50%;
  font-weight: 300;
  font-size: 11px;
  font-size: 0.73333rem;
}

.b-agenda .view-content .views-row .lugar {
  padding: 4px 5px 2px;
  color: #ffde2f;
  display: inline-block;
  background: rgba(255, 255, 255, 0.15);
  flex: 1 1 50%;
  font-weight: 700;
  font-size: 11px;
  font-size: 0.73333rem;
}

.b-contactos .view-content .views-row {
  flex: 1 1 48%;
  max-width: 48%;
  margin: 1%;
  padding: 2%;
  padding-left: 4%;
  background: #fff;
  color: #261b29;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  border-radius: 2px;
}

.b-contactos .view-content .views-row:before {
  content: '';
  height: 115px;
  display: inline-block;
  position: relative;
  float: left;
  background: linear-gradient(to bottom, #e5f902 0%, #e5f902 50%, #b610ea 50%, #b610ea 100%);
  width: 2px;
  margin-left: -5%;
}

@media screen and (max-width: 768px) {
  .b-contactos .view-content .views-row:before {
    margin-left: -2.5%;
  }
}

@media screen and (max-width: 768px) {
  .b-contactos .view-content .views-row {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.b-contactos .view-content .views-row a {
  color: #22c6dc;
}

.b-contactos .view-content .views-row .photo {
  float: left;
  margin-right: 10px;
}

.b-contactos .view-content .views-row .photo img {
  max-width: 100px;
  border-radius: 500px;
  margin-top: 10px;
  margin-bottom: -10px;
}

.b-contactos .view-content .views-row .title {
  font-size: 18px;
  font-size: 1.2rem;
  margin-top: 0px;
  margin-bottom: 0px;
}

.b-contactos .view-content .views-row .rol {
  text-transform: uppercase;
  font-size: 27px;
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 27px;
  margin: 8px 0px;
}

.b-contactos .view-content .views-row .phone a {
  font-size: 15px;
  font-size: 1rem;
  color: #261b29;
}

.b-contactos .view-content .views-row .phone a:before {
  content: "";
  text-align: center;
}

.b-contactos .view-content .views-row .phone a:before {
  margin-right: 4px;
}

.b-contactos .view-content .views-row .email a {
  font-size: 15px;
  font-size: 1rem;
}

.b-contactos .view-content .views-row .email a:before {
  content: "";
  text-align: center;
}

.b-contactos .view-content .views-row .email a:before {
  margin-right: 4px;
}

.theme-contactos .block-formblock {
  background: #fff;
  padding: 20px;
  color: #261b29;
  border: 4px solid #261b29;
  box-shadow: 0 0 0px 8px #fff;
  width: calc(100% - 32px);
  margin: auto;
}

.theme-contactos .block-formblock h2.block-title {
  color: #261b29;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 36px;
  font-size: 2.4rem;
  line-height: 36px;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 0px;
}

.theme-contactos .block-formblock label {
  text-transform: uppercase;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
}

.theme-contactos .block-formblock input, .theme-contactos .block-formblock textarea {
  border-radius: 0px !important;
  border-top: 0px solid #fff !important;
  border-left: 0px solid #fff !important;
  border-right: 0px solid #fff !important;
  font-family: "uniform", helvetica, arial, sans-serif;
  margin-bottom: 20px;
  width: 100%;
}

.theme-contactos .block-formblock input[type="checkbox"], .theme-contactos .block-formblock label.option {
  display: inline-block;
  width: auto;
  margin-bottom: 10px;
}

.theme-contactos .block-formblock label.option {
  top: -3px;
  position: relative;
}

.b-catalogos h3.title, .page-catalogos h3.title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 14px;
  font-size: 0.93333rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background: #ffde2f;
  border-radius: 2px;
}

.b-catalogos h3.title:before, .page-catalogos h3.title:before {
  content: "";
  text-align: center;
}

.b-catalogos h3.title:before, .page-catalogos h3.title:before {
  background: rgba(38, 27, 41, 0.2);
  margin-right: 6px;
  color: #ffde2f;
  padding: 15px 6px;
  width: 30px;
}

.b-catalogos h3.title a, .page-catalogos h3.title a {
  color: #261b29;
}

.b-catalogos h3.title a:hover, .page-catalogos h3.title a:hover {
  color: #ed5061;
}

.page-catalogos .view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 5%;
}

.page-catalogos .view-content .views-row {
  flex: 1 1 100%;
  padding: 0 2%;
}

@media (min-width: 480px) {
  .page-catalogos .view-content .views-row {
    flex: 1 1 100%;
  }
}

@media (min-width: 768px) {
  .page-catalogos .view-content .views-row {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

.page-catalogos .view-content .views-row h3 {
  text-align: left;
  margin-bottom: 10px !important;
}

.b-medios .Vídeo {
  position: relative;
}

.b-medios .Vídeo:before {
  content: '';
  width: 50px;
  height: 32px;
  top: calc(50% - 32px);
  left: calc(50% - 25px);
  background-image: url(../img/video.png);
  background-color: rgba(0, 0, 0, 0.3);
  display: block;
  transform-origin: center center;
  position: absolute;
  background-repeat: no-repeat;
  box-shadow: 0 0 60px black;
}

.b-medios .Audio {
  position: relative;
}

.b-medios .Audio:before {
  content: '';
  width: 51px;
  height: 40px;
  top: calc(50% - 40px);
  left: calc(50% - 25px);
  background-image: url(../img/audio.png);
  background-color: rgba(0, 0, 0, 0.3);
  display: block;
  transform-origin: center center;
  position: absolute;
  background-repeat: no-repeat;
  box-shadow: 0 0 60px black;
}

.b-medios .Documento {
  position: relative;
}

.b-medios .Documento:before {
  content: '';
  width: 30px;
  height: 35px;
  top: calc(50% - 35px);
  left: calc(50% - 15px);
  background-image: url(../img/doc.png);
  background-color: rgba(0, 0, 0, 0.3);
  display: block;
  transform-origin: center center;
  position: absolute;
  background-repeat: no-repeat;
  box-shadow: 0 0 60px black;
}

.b-medios img {
  margin-bottom: 0px;
  transition: 0.3s all;
}

.b-medios img:hover {
  opacity: 0.7;
}

.b-medios .type {
  display: none;
}

.b-medios h2.title {
  margin: 0px;
}

.b-medios h2.title a {
  font-family: "uniformExtraCondensed", helvetica, arial, sans-serif;
  font-size: 14px;
  font-size: 0.93333rem;
  line-height: 16px;
  text-transform: uppercase;
  color: #261b29;
  background: #fff;
  font-weight: 300;
  display: block;
  padding: 8px 20px 6px;
}

.b-medios h2.title a:after {
  display: none !important;
}

.b-medios h2.title a:hover {
  opacity: 0.5;
}

.b-medios .program {
  display: none;
}

.view-filters form {
  margin-bottom: 16px;
}

.view-filters form label {
  text-transform: uppercase;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 900;
  padding: 0px 8px;
  font-size: 12px;
  font-size: 0.8rem;
}

.view-filters form select {
  border-radius: 0px;
  -webkit-appearance: none;
  border: 0px;
  padding: 4px 8px;
  margin: 4px 8px;
  color: #ffde2f;
  background: transparent;
  border-bottom: 1px solid #ffde2f;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 0.8rem;
}

.view-filters form .views-submit-button input {
  margin-top: 17px !important;
  background: #ffde2f;
  color: #261b29;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
}

.view-filters form .views-submit-button input:hover {
  background: #fbd300;
  color: #261b29;
}

.node-type-program .l-highlighted, .node-type-organization .l-highlighted {
  align-items: center;
  margin-bottom: 5px;
}

.node-type-program .l-highlighted .b-addthis, .node-type-organization .l-highlighted .b-addthis {
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
}

.node-type-program .l-highlighted .b-addthis a, .node-type-organization .l-highlighted .b-addthis a {
  margin-bottom: 0px;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
}

.node-type-program .node-program, .node-type-program .node-organization, .node-type-organization .node-program, .node-type-organization .node-organization {
  display: flex;
  flex-wrap: wrap;
}

.node-type-program .node-program .field-name-title, .node-type-program .node-organization .field-name-title, .node-type-organization .node-program .field-name-title, .node-type-organization .node-organization .field-name-title {
  flex: 1 1 100%;
}

.node-type-program .node-program .field-name-field-img-main, .node-type-program .node-organization .field-name-field-img-main, .node-type-organization .node-program .field-name-field-img-main, .node-type-organization .node-organization .field-name-field-img-main {
  flex: 1 1 100%;
}

.node-type-program .node-program .field-name-body, .node-type-program .node-organization .field-name-body, .node-type-organization .node-program .field-name-body, .node-type-organization .node-organization .field-name-body {
  text-align: justify;
}

@media screen and (min-width: 768px) {
  .node-type-program .node-program .field-name-body, .node-type-program .node-organization .field-name-body, .node-type-organization .node-program .field-name-body, .node-type-organization .node-organization .field-name-body {
    width: 60%;
    padding-right: 20px;
  }
}

.node-type-program .node-program .view-info-per-node, .node-type-program .node-organization .view-info-per-node, .node-type-organization .node-program .view-info-per-node, .node-type-organization .node-organization .view-info-per-node {
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  margin-left: 0px;
  border: 8px solid #fff;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 10px #ffde2f;
  max-width: 100% !important;
  width: 100%;
  align-self: flex-start;
}

.node-type-program .node-program .view-info-per-node a, .node-type-program .node-organization .view-info-per-node a, .node-type-organization .node-program .view-info-per-node a, .node-type-organization .node-organization .view-info-per-node a {
  font-weight: 600;
}

.node-type-program .node-program .view-info-per-node .contacts ul, .node-type-program .node-organization .view-info-per-node .contacts ul, .node-type-organization .node-program .view-info-per-node .contacts ul, .node-type-organization .node-organization .view-info-per-node .contacts ul {
  list-style: none;
}

.node-type-program .node-program .view-info-per-node .contacts ul li, .node-type-program .node-organization .view-info-per-node .contacts ul li, .node-type-organization .node-program .view-info-per-node .contacts ul li, .node-type-organization .node-organization .view-info-per-node .contacts ul li {
  margin-left: 5px;
  color: transparent;
  list-style: none;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 0.8rem;
  font-weight: 900;
}

.node-type-program .node-program .view-info-per-node .contacts ul li ul.links, .node-type-program .node-organization .view-info-per-node .contacts ul li ul.links, .node-type-organization .node-program .view-info-per-node .contacts ul li ul.links, .node-type-organization .node-organization .view-info-per-node .contacts ul li ul.links {
  list-style: none;
  margin-bottom: -5px;
  margin-top: -20px;
}

.node-type-program .node-program .view-info-per-node .contacts ul li ul.links li, .node-type-program .node-organization .view-info-per-node .contacts ul li ul.links li, .node-type-organization .node-program .view-info-per-node .contacts ul li ul.links li, .node-type-organization .node-organization .view-info-per-node .contacts ul li ul.links li {
  list-style: none;
  margin-bottom: 0px;
}

.node-type-program .node-program .view-info-per-node .contacts ul li ul.links li a, .node-type-program .node-organization .view-info-per-node .contacts ul li ul.links li a, .node-type-organization .node-program .view-info-per-node .contacts ul li ul.links li a, .node-type-organization .node-organization .view-info-per-node .contacts ul li ul.links li a {
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-size: 14px;
  font-size: 0.93333rem;
  font-weight: 700;
  margin-bottom: 0px;
  text-transform: capitalize;
  color: #261b29;
}

.node-type-program .node-program .view-info-per-node .contacts ul li div.field-name-field-txt-rol, .node-type-program .node-organization .view-info-per-node .contacts ul li div.field-name-field-txt-rol, .node-type-organization .node-program .view-info-per-node .contacts ul li div.field-name-field-txt-rol, .node-type-organization .node-organization .view-info-per-node .contacts ul li div.field-name-field-txt-rol {
  margin-left: 20px;
  margin-bottom: 6px;
  padding-left: 6px;
  font-size: 12px;
  font-size: 0.8rem;
  text-transform: capitalize;
  font-weight: 400;
  color: #000;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .node-type-program .node-program .view-info-per-node, .node-type-program .node-organization .view-info-per-node, .node-type-organization .node-program .view-info-per-node, .node-type-organization .node-organization .view-info-per-node {
    flex: 1 1 35%;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 28px;
  }
}

.node-type-contact .field {
  margin-bottom: 10px;
}

.node-type-contact .field .label-above {
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 900;
}

.node-type-webform b, .node-type-webform strong, .node-type-webform label, .node-type-webform .label-above {
  font-weight: 700;
}

.node-type-webform .form-item .description {
  margin: 1em 0;
}

.node-type-webform .form-item .description b, .node-type-webform .form-item .description strong, .node-type-webform .form-item .description label, .node-type-webform .form-item .description .label-above {
  font-weight: 600;
}

.node-type-webform .field-name-field-file-attachment {
  margin: 2em 0;
}

.node-type-webform .field-name-field-file-attachment .label-above {
  margin: 1em 0;
}

.node-type-webform table {
  width: 100%;
}

.node-type-produccion .l-highlighted {
  display: none;
}

.node-type-produccion .node-produccion {
  margin-top: 30px;
}

.node-type-produccion .view-mode-full {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px;
}

.node-type-produccion .view-mode-full .group-pais-fecha-imagen {
  flex: 1 1 30%;
}

@media screen and (max-width: 768px) {
  .node-type-produccion .view-mode-full .group-pais-fecha-imagen {
    flex: 1 1 100%;
  }
}

.node-type-produccion .view-mode-full .group-pais-fecha-imagen .field-name-pais-fecha {
  position: absolute;
  top: 0;
  transform: rotate(90deg);
  transform-origin: left top;
  margin-left: -8px;
  margin-top: 40px;
}

.node-type-produccion .view-mode-full .group-pais-fecha-imagen .field-name-pais-fecha span.pais, .node-type-produccion .view-mode-full .group-pais-fecha-imagen .field-name-pais-fecha span.fecha {
  font-size: 10px;
  font-size: 0.66667rem;
  font-weight: 900;
  text-transform: uppercase;
  padding: 4px 26px 2px;
}

.node-type-produccion .view-mode-full .group-pais-fecha-imagen .field-name-pais-fecha span.pais {
  background: #f27e8b;
  color: #fff;
}

.node-type-produccion .view-mode-full .group-pais-fecha-imagen .field-name-pais-fecha span.fecha {
  background: #ffde2f;
  color: #111;
}

.node-type-produccion .view-mode-full .group-direccion-titulo {
  flex: 1 1 70%;
  padding-left: 0px;
}

@media screen and (min-width: 768px) {
  .node-type-produccion .view-mode-full .group-direccion-titulo {
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .node-type-produccion .view-mode-full .group-direccion-titulo {
    flex: 1 1 100%;
  }
}

.node-type-produccion .view-mode-full .group-direccion-titulo .field-name-field-eref-persona-direccion {
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  font-weight: 100;
  font-size: 25px;
  font-size: 1.66667rem;
  line-height: 25px;
  text-transform: uppercase;
}

.node-type-produccion .view-mode-full .group-direccion-titulo .field-name-title h2.page-title {
  color: #f27e8b;
  text-align: left;
}

.node-type-produccion .view-mode-full .group-direccion-titulo .group-ficha-tecnica .field, .node-type-produccion .view-mode-full .group-direccion-titulo .group-creditos .field {
  margin-bottom: 10px;
  font-size: 14px;
  font-size: 0.93333rem;
}

.node-type-produccion .view-mode-full .group-sinopsis {
  font-size: 14px;
  font-size: 0.93333rem;
  line-height: 24px;
}

.node-type-produccion .view-mode-full .group-sinopsis .label-above {
  font-weight: 100;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-size: 0.8rem;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.node-type-produccion .view-mode-full .group-video {
  width: 100%;
}

.node-type-produccion .view-mode-full .group-video .label-above {
  font-weight: 100;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-size: 0.8rem;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.node-type-produccion .view-mode-full .group-video .field-name-field-video-vimeo .label-above {
  color: #fff;
}

.node-type-produccion .view-mode-full .group-video .field-name-field-video-vimeo a {
  padding: 8px 20px 4px;
  background: #4588d5;
  color: #fff;
  display: block;
  text-align: center;
  font-family: "uniformCondensed", helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  border: 1px solid #4588d5;
}

.node-type-produccion .view-mode-full .group-video .field-name-field-video-vimeo a:hover {
  color: #4588d5;
  background: #fff;
}

.front .field-name-field-img-header {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  height: calc(20vh + 30vw);
}

.front .field-name-field-img-header:before {
  content: "";
  background: url("../img/logo-white.png");
  height: 72px;
  width: 220px;
  background-size: 100%;
  display: inline-block;
  align-self: center;
  display: none;
}

.front .field-name-field-img-header img {
  height: 0px !important;
}

.front .field-name-field-img-header img.vert-logo {
  opacity: 1 !important;
  height: 100% !important;
  align-self: flex-end;
  width: auto;
}

.front .l-content .block-views h2.block-title {
  font-size: 12px;
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "uniform", helvetica, arial, sans-serif;
  margin: 0 0 20px 0;
  padding: 10px 0;
  border-bottom-color: #46464b;
  border-bottom: 1px solid #46464b;
}

.front .l-content .block-views .more-link, .front .l-content .block-views .views-more-link {
  display: block;
  font-size: 10px;
  font-size: 0.66667rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "geomanist", helvetica, arial, sans-serif;
  font-weight: 900;
  margin-top: -45px;
  margin-bottom: 45px;
}

.front .l-content .block-views .more-link:after, .front .l-content .block-views .views-more-link:after {
  content: "";
  text-align: center;
}

.front .l-content .block-views .more-link a, .front .l-content .block-views .views-more-link a {
  color: #fff;
}

.front .l-content .block-views .more-link:after, .front .l-content .block-views .views-more-link:after {
  margin-left: 0px;
  font-weight: 900;
}

.front .l-content .block-views .views-more-link {
  margin-top: 18px;
  margin-bottom: 0px;
  align-self: flex-end;
}
