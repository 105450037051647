////
/// Main menu
/// @group Components
////


// Main menu
//----------------------------------------------------

.l-nav {
  max-width: 100vw !important;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  // width: 100vw;
  left: 0px;
  right: 0px;
  position: relative;
  align-content: flex-end;
  justify-content: flex-end;
  min-height: 50px;
  flex: 1;
  margin-right: 10px;
  @media screen and (max-width: 400px){
    flex: 1 1 100%;
    width: 100%;
    justify-content: center;
  }
}

.nav-main {

// when inside .l-header
//----------------------------------
  @include when-inside('.l-header') {
    // padding: 4px;
    z-index: 999;
    // position: absolute;
    // top: 36px;
    // right: 0;
    // @include breakpoint($s) {top: 60px;}
    // @include breakpoint($m) {top: 80px;}
    align-self: center;
    position: relative;
    // top: 12px;

    h2 {
      background: transparent;
      font-family: $uniform;
      @include font-size(15px);
      font-weight: 800;
      color: #000;
      cursor: pointer;
      margin: 0;
      padding: 4px 10px;
      @include font-size(12px);
      text-transform: uppercase;
      // @include icon-after(menu);
      border: 1px solid black;
      display: flex;
      align-content: center;
      align-items: center;
      transition: all 0.3s;
      &:after {
        margin-left: 40px;
      }

      &:hover {
        div.icon {
          width: 15px;
          margin-left: 41px;
          &:before {
            width: 15px;
            margin-left: 0px;
          }
  
          &:after {
            width: 15px;
          }
        }    
      }

      div.icon {
        background: black;
        height: 3px;
        width: 11px;
        margin-left: 45px;
        position: relative;
        float: right;
        right: 0px;
        transition: all 0.3s;

        &:before {
          content: '';
          background: black;
          height: 3px;
          width: 15px;
          top: -4px;
          position: absolute;
          margin-left: -4px;
          transition: all 0.3s;
        }

        &:after {
          content: '';
          background: black;
          height: 3px;
          width: 11px;
          top: 4px;
          position: absolute;
          transition: all 0.3s;
        }
      }
    }

    ul.menu {
      display: none;
      background: $color-accent;
      position: absolute;
      padding: 3vh 10px;
      right: 0px;
      top: 36px;
      border-left: 1px solid #f4ff73;
      border-right: 1px solid #f4ff73;

      @media screen and (max-width: 400px) {
       right: -30px;
       left: -30px
      }
      .menu-logo {
        text-align: center;
        margin-top: -3vh;
        opacity: 0.3;
      }
      .close-menu {
        font-family: $uniformCondensed;
        font-weight: 700;
        text-align: center;
        background: rgba(0,0,0,0.05);
        @include font-size(10px);
        color: black;;
        margin-top: 10px;
        padding: 10px;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: -3vh;
        letter-spacing: 4px;
        &:hover {
          letter-spacing: 0px;
        }
      }
      li {
        padding: 10px 20px;
        @include font-size(13px);
        border-bottom: 1px solid rgba(0,0,0,0.02);
        text-align: center;
        a {
          color: $black;
          letter-spacing: 1px;
          font-weight: 900;
          text-transform: uppercase;
          font-family: $uniformCondensed;
          &:hover {
            color: $color-primary;
          }
        }
        &.last {border-bottom: none;}
      }
    }
  }

} // .nav-main
