.b-programas {
  // .node-grid {
    .view-content { 
      .views-row {
        flex: 1 0 100%;
        padding: 0px 20px;
        margin-bottom: 60px;

        @media screen and (min-width: $m) {
          flex: 1 0 50%;
          max-width: 50%;
        }

        h2.title {
          margin-top: 0px;
          margin-bottom: 10px;
          font-family: $uniform;
          font-weight: 700;
          @include font-size(16px);
          text-transform: uppercase;
          a:link, a:visited, a:active, li a.active {
            color: $color-link;
            @include on-event {
              color: $color-link-hover;
              outline: 0;
            }
          }
        }

        .lead {
          @include font-size(15px);
          font-family: $uniform;
          font-weight: 300;
          line-height: 20px;
          color: #cdcdcf;
          .views-more-link {
            margin-top: 18px;
          }
        }
      }
    }
  // }  
}