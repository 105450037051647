.alt-order-rows {

  .views-row {
    flex-direction: column;
    margin: 40px 0;
    display: flex;
    background: #097beb;

    .text, .media {
      flex: 1 1 100%;
    }

    .text {
      padding: 20px 40px;
      text-transform: uppercase;
      justify-content: center;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      .type {
        color: #f9f608;
        line-height: 18px;
        margin-bottom: 4px;
      }
      h3.title {
        font-family: $uniformCondensed;
        font-weight: 900;
        margin-top: 0px;
        margin-bottom: 30px;
        @include font-size(40px);
        line-height: 40px;
        a {
          color: #87df69;
          &:hover {
            color: darken(#87df69, 10%);
          }
        }
      }
      .more a {
        padding: 8px 16px 7px;
        background: transparent;
        color: #fff;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 12px;
        font-family: $uniform;
        line-height: 12px;
        letter-spacing: 2px;
        border: 1px solid #fff;
        @include font-size(12.5px);
        @include icon-after(right);
        &:after {
          margin-left: 5px;
          font-weight: 900;
        }
  
        &:hover {
          background: #fff;
          color: $color-link-hover;
        }
      }
    }

    .media {
      img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    &:nth-child(2n) {
      background: #f37d8d;
      .media {
        order: 2;
      }
    }

    @include breakpoint($m) {
      flex-direction: row;
      .text,
      .media {
        flex: 1 1 50%;
      }
    }

  } // .views-row
} // .alt-order-rows
