// Nodes grid
//----------------------------------------------------

// layout
//----------------------------------
.node-grid {
  margin: 10px 0;
  padding: 20px 0;

  .view-content {
    display: flex;
    flex-wrap: wrap;
    .views-row {
      flex: 1 0 300px;
      margin-bottom: 20px;
      padding: 0 15px;
      @media screen and (min-width: 768px) {
        max-width: 50%; 
      }
      @media screen and (min-width: 960px) {
        max-width: 33%; 
      }
    } // .views-row
  } // .view-content

// events
//----------------
  .view-events .views-row {
    // &:nth-child(2),
    // &:nth-child(5)
    &:nth-of-type(3n+2) {
      border-right: 1px dotted $color-divider;
      border-left: 1px dotted $color-divider;
    }
  } // .view-events

} // .node-grid

// styles
//----------------------------------
.node-grid {

  a {
    color: $white;
    &:hover {
      color: $color-link;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  span {
    @include font-size(12px);
    text-transform: uppercase;
    &.label,
    &.date {
      padding: 2px 26px;
    }
    &.label {
      background: $black;
      color: #fff;
    }
    &.date {
      background: $color-accent;
    }
  }

  h2.title {
    @include font-size(18px);
  }

} // .node-grid
