////
/// Mixins
/// @group Abstracts
/// This file contains application-wide Sass mixins.
////

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

//----------------------------------------------------
//----------------------------------------------------

// SIZE
//----------------------------------------------------
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// FONT SIZE
//----------------------------------------------------
@mixin font-size($font-size) {
  font-size: $font-size;
  font-size: $font-size / $base-font-size * 1rem;
}

// IMAGES
//----------------------------------------------------

// Logo
//----------------------------------
@mixin logo($name, $width: false, $height: false, $position: false) {
  @extend %replace-image;
  display: block;
  background-image: url(../img/#{$name}.svg);
  .no-svg & {
    background-image: url(../img/#{$name}.png);
  }
  background-repeat: no-repeat;
  @if $width {
    width: $width;
  }
  @if $height {
    height: $height;
  }
  @if $position {
    background-position: $position;
  }
}

// SVG background image with PNG fallback
//----------------------------------
@mixin bg-img($name, $size: false) {
  background-image: url(../img/#{$name}.svg);
  @if $size {
    background-size: $size;
  }
  .no-svg & {
    background-image: url(../img/#{$name}.png);
  }
}

// ICONS
//----------------------------------------------------

// Icon Before
//----------------------------------
@mixin icon-before($content) {
  @extend %icon-before;
  &:before {
    content: icon($content);
    text-align: center;
  }
}

// Icon After
//----------------------------------
@mixin icon-after($content) {
  @extend %icon-after;
  &:after {
    content: icon($content);
    text-align: center;
  }
}

// BUTTONS
//----------------------------------------------------
@mixin btn($color) {
  @extend %btn;
  a {
    background: $color;
    border-bottom: 2px solid darken($color, 10%);
    &:hover {background: darken($color, 10%);}
  }
}
