.b-agenda {
  .view-content {
    align-items: flex-start;
    .views-row {
      display: flex;
      flex-wrap: wrap;
      transform: scale(1);
      transition: all 0.3s; 

      &:hover {
        transform: scale(1.05); 
      }

      span {
        text-align: center !important;
      }

      .date {
        padding: 0px 5px 0px;
        font-weight: 500;
        color: #261b29;
        flex: 1 1 100%;
        @include font-size(18px);
        @include icon-before(date);
        &:before {
          @include font-size(16px);
          margin-right: 4px;
          color: #261b29;
        }
      }
      .programa {
        background: rgba(255,255,255,0.1);
        padding: 4px 5px 2px;
        font-weight: 700;
        color: #fff;
        flex: 1 1 100%;
        @include font-size(11px);
      }
      h2.title {
        flex: 1 1 100%;
        @include font-size(20px);
        text-transform: uppercase;
        font-family: $uniformCondensed;
        margin-top: 6px;
        font-weight: 900;
        line-height: 24px;
        margin-top: 0px;
        padding: 8px 4px 4px;
        text-align: center;
        background: rgba(255,255,255,0.05);
      }
      img {
        margin-bottom: 0px;
        flex: 1 1 100%;
      }
      .type {
        padding: 4px 5px 2px;
        color: $color-accent;
        display: inline-block;
        background: rgba(255,255,255,0.1);
        flex: 1 1 50%;
        font-weight: 300;
        @include font-size(11px);
      }
      .lugar {
        padding: 4px 5px 2px;
        color: $color-accent;
        display: inline-block;
        background: rgba(255,255,255,0.15);
        flex: 1 1 50%;
        font-weight: 700;
        @include font-size(11px);
      }
    }
  }
}