// SEARCH PAGE / SEARCH BLOCK
//----------------------------------------------
.search-form,
.block-search {

  .form-type-searchfield {
    max-width: 85%;
  }

  input[type="text"] {
    padding: 0;
    border-width: 0 0 1px 0 !important;
    border-radius: 0 !important;
  }

  input[type="submit"] {
    font-family: $icon;
    margin: 0;
    padding: 0;
    background-image: url(../img/search.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-accent;
    color: transparent !important;
    border-radius: 3px 3px 3px 0px;

    .l-content & {
      position: relative;
      top: -18px;
    }

    &:hover {
      opacity: 0.3;
    }
  }

  input[type="search"] {
    background: transparent;
    border-radius: 0px;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 1px solid black !important;
    min-width: 30vw;
    .l-content & {
      color: #fff;
      border-bottom: 1px solid $color-accent !important;
    }

    @media screen and (max-width: 768px){
      min-width: 25vw;
    }
  }

  .container-inline {
    display: flex;
    align-items: flex-end;

    .form-item-search-block-form {
      flex: 1;
      margin: 0px;
    }
  }
}


.block-search {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  flex: 1;
  @media screen and (max-width: 680px){
    display: none;
  }

  .block-content {
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;

    input[type="search"] {
      background: transparent;
      border-radius: 0px;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
      border-bottom: 1px solid black !important;
      min-width: 260px;
      @media screen and (max-width: 768px){
        min-width: 25vw;
      }
    }

    input[type="submit"] {
      background-image: url(../img/search.png);
      background-position: center center;
      background-repeat: no-repeat;
      color: transparent;
      &:hover {
        opacity: 0.3;
      }
    }
  }
}

// SEARCH PAGE
//----------------------------------------------
.search-form {
  margin: 0px auto 0px 0;
  padding-top: 5px;
  padding-bottom: 10px;

  .search-advanced {
    display: none;
  }

  label {
    display: none;
  }

  input[type="text"] {
    @include font-size(32px);

    height: 40px;
    background: none;
    min-width: 240px;
    color: #000;
    border-color: #000 !important;
    background: transparent;
  }

  input[type="submit"] {
    @include size(40px);
    @include font-size(24px);
    color: #000;

    &:hover {
      color: $color-link;
    }
  }

  ::-webkit-input-placeholder {
    color: #000;
  }

  :-moz-placeholder {
    color: #000;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: #000;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #000;
  }
}

// SEARCH BLOCK
//----------------------------------------------
.block-search {
  float: right;
  text-align: center;

  input[type="text"] {
    min-width: 95%;
    color: #000;
    border-width: 0 0 1px 0 !important;
    border-color: #000 !important;
    border-radius: 0 !important;
    background: transparent;

    @media (max-width: 520px) {
      // display: none;
    }
  }

  input[type="submit"] {
    margin: 0 0 -30px 0;

    @include size(24px);

    color: #000;

    &:hover {
      color: #fff;
    }
  }

  ::-webkit-input-placeholder {
    color: #000;
  }

  :-moz-placeholder {
    color: #000;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: #000;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #000;
  }
}

// SEARCH RESULTS
//----------------------------------------------
.page-search {
  h1.page-title {
    // display: none;
    font-family: $uniformCondensed;
    @include font-size(40px);
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 5px;
  }
}

.search-result {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-divider;

  strong {
    color: yellow;
  }

  h3.title {
    @include font-size(24px);

    margin: 0;
    font-weight: normal;
  }
}
