
.b-producciones {
  padding-top: 40px;

  form#views-exposed-form-productions-block {
    margin-bottom: 16px;
    label {
      text-transform: uppercase;
      font-family: $uniformCondensed;
      font-weight: 900;
      padding: 0px 8px;
      @include font-size(12px);
    }
    select {
      border-radius: 0px;
      -webkit-appearance: none;
      border: 0px;
      padding: 4px 8px;
      margin: 4px 8px;
      color: $color-accent;
      background: transparent;
      border-bottom: 1px solid $color-accent;
      font-family: $uniformCondensed;
      @include font-size(12px);
    }

    .views-submit-button {
      input {
        margin-top: 17px;
        background: $color-accent;
        color: #261b29;
        font-family: $uniformCondensed;
        &:hover {
          background: darken($color-accent, 10%);
          color: #261b29;
        }
      }
    }
  }

  .view-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .views-row {
      position: relative;
      flex: 0 0 100%;
      margin-bottom: 60px;
      display: flex;
      flex-wrap: wrap; 
      @media screen and (min-width:768px) {
        flex: 0 0 50%;
        padding: 0 2%;
      }
    } // .views-row
  } // .view-content

  // .info, .media, .text {}
  // .info {flex: 0 0 span(1);}
  // .media {flex: 0 0 span(5);}
  // .text {flex: 0 0 span(6);}


  .info {
    position: absolute;
    top: 0;
    transform: rotate(90deg);
    transform-origin: left top;
  }

  span {
    @include font-size(12px);
    font-weight: 600;
    text-transform: uppercase;
    &.pais,
    &.lanzamiento {
      padding: 2px 26px;
    }
    &.pais {
      background: $color-link;
      color: $white;
    }
    &.lanzamiento {
      background: $color-accent;
      color: $black;
    }
  }

  .media {
      flex: 1 1 100%;
      padding: 0 10px 20px;
      @media screen and (min-width:1024px) {
        flex: 0 0 35%;
      }
    img {
      display: block;
    }
  }

  .text {
    padding: 0 10px;
    flex: 1 1 100%;
    @media screen and (min-width:1024px) {
      flex: 0 0 65%;
    }
    .direccion {
      text-transform: uppercase;
      color: #e5f902;
      font-family: $uniformCondensed;
      font-weight: 100;
      @include font-size(24px);
      line-height: 26px;
    }
    h3.title {
      font-family: $uniformCondensed;
      text-transform: uppercase;
      font-weight: 900;
      @include font-size(40px);
      line-height: 40px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .more a {
      padding: 8px 16px 7px;
      background: #097beb;
      color: #fff;
      text-transform: uppercase;
      font-weight: 800;
      line-height: 12px;
      font-family: $uniform;
      line-height: 12px;
      letter-spacing: 2px;
      @include font-size(12.5px);
      @include icon-after(right);
      &:after {
        margin-left: 5px;
        font-weight: 900;
      }

      &:hover {
        background: $color-accent;
        color: #000;
      }
    }
  }

} // .b-producciones
