// Quick links menu
//----------------------------------------------------

.nav-quick-links {

// layout
//----------------------------------
  margin: 20px 0;
  ul.menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex: 1 1 50px;
    }

// styles
//----------------------------------
    li {
      margin: 4px;
      padding: 8px;
      @include font-size(12px);
      text-transform: uppercase;
      // font-weight: 600;
      text-align: center;
      background: $black;
      a {
        color: #fff;
        &:hover {
          color: $color-link;
        }
      }
      @include breakpoint($l) {
        &.first {margin-left: 0;}
        &.last {margin-right: 0;}
      }
    }

  } // ul.menu
} // .nav-quick-links



// when inside .l-footer
//----------------------------------
.nav-quick-links {
  @include when-inside('.l-footer') {
    border-top: 1px solid #3b3540;
    border-bottom: 1px solid #3b3540;
    ul.menu li {
      background: transparent;
      a {
        color: $white;
        @include font-size(9px);
        color: #fff;
        font-weight: 600;
        letter-spacing: 1px;
        &:hover {
          color: $color-link;
        }
      }
    } // ul.menu
  } // when-inside .l-footer
} // .nav-quick-links
