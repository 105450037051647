.node-type-produccion {

  .l-highlighted {
    display: none;
  }

  .node-produccion {
    margin-top: 30px;
  }

  .view-mode-full {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px;

    .group-pais-fecha-imagen {
      flex: 1 1 30%;
      @media screen and (max-width:768px){
        flex: 1 1 100%;
      }
      .field-name-pais-fecha {
        position: absolute;
        top: 0;
        transform: rotate(90deg);
        transform-origin: left top;
        margin-left: -8px;
        margin-top: 40px;
        span {
          &.pais,
          &.fecha {
          @include font-size(10px);
          font-weight: 900;
          text-transform: uppercase;
            padding: 4px 26px 2px;
          }
          &.pais {
            background: $color-link;
            color: $white;
          }
          &.fecha {
            background: $color-accent;
            color: $black;
          }
        }
      }
    }

    .group-direccion-titulo{
      flex: 1 1 70%;
      padding-left: 0px;
      @media screen and (min-width:768px ){
        padding-left: 30px;
      }
      @media screen and (max-width:768px ){
        flex: 1 1 100%;
      }

      .field-name-field-eref-persona-direccion {
        font-family: $uniformCondensed;
        font-weight: 100;
        @include font-size(25px);
        line-height: 25px;
        text-transform: uppercase;
      }
      .field-name-title {
        h2.page-title {
          color: $color-link;
          text-align: left;
        }
      }

      .group-ficha-tecnica, .group-creditos {
        .field {
          margin-bottom: 10px;
          @include font-size(14px);
        }
      }
    }

    .group-sinopsis {
      @include font-size(14px);
      line-height: 24px;
      .label-above {
        font-weight: 100;
        letter-spacing: 2px;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(0,0,0,0.4);
        @include font-size(12px);
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }

    .group-video {
      width: 100%;
      .label-above {
        font-weight: 100;
        letter-spacing: 2px;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(0,0,0,0.4);
        @include font-size(12px);
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      .field-name-field-video-vimeo {
        .label-above {
          color: #fff;
        }
        a {
          padding: 8px 20px 4px;
          background: #4588d5;
          color: #fff;
          display: block;
          text-align: center;
          font-family: $uniformCondensed;
          text-transform: uppercase;
          font-weight: 900;
          border: 1px solid #4588d5;

          &:hover {
             color: #4588d5;
             background: #fff;
          }
        }
      }
    }
  }
} // .node-type-produccion
