// IMAGES
//----------------------------------------------------
img, media {
  max-width: 100%;
  height: auto;
}

// FIGURE
//----------------------------------------------------
figure {
  position: relative;
  margin: 0;
  padding: 0;
  img {
    display: block;
    width: 100%;
  }
}
figcaption {}
