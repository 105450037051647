.view-filters { 
  form {
    margin-bottom: 16px;
    label {
      text-transform: uppercase;
      font-family: $uniformCondensed;
      font-weight: 900;
      padding: 0px 8px;
      @include font-size(12px);
    }
    select {
      border-radius: 0px;
      -webkit-appearance: none;
      border: 0px;
      padding: 4px 8px;
      margin: 4px 8px;
      color: $color-accent;
      background: transparent;
      border-bottom: 1px solid $color-accent;
      font-family: $uniformCondensed;
      @include font-size(12px);
    }

    .views-submit-button {
      input {
        margin-top: 17px !important;
        background: $color-accent;
        color: #261b29;
        font-family: $uniformCondensed;
        &:hover {
          background: darken($color-accent, 10%);
          color: #261b29;
        }
      } 
    }
  }
}
