.node-type-webform {

  b, strong, label, .label-above {
    font-weight: 700;
  }

  .form-item .description {
    margin: 1em 0;
    b, strong, label, .label-above {
      font-weight: 600;
    }
  }

  .field-name-field-file-attachment {
    margin: 2em 0;
    .label-above {
      margin: 1em 0;
    }
  }


  table {
    width: 100%;
    // display: flex;
    // td {
    //   flex: 1 0 50%;
    // }
  }

} // .node-type-webform
